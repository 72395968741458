import { Department, EmployeeEnrollment, Position } from '../services/prodocApi';
import { actionCreator, isType } from './Action';
import { showErrorMessage, showSuccessMessage } from './notificationStore';
import { PAGES_ITEMS, setPageItemSaved } from './pagesStore';
import { goBack, navigateTo, navigateToHome } from './navigationStore';
import prodocApi from '../services/prodocApi';
import { UtilsNavigation } from '../utils/UtilsNavigation';
import { setOverviewData } from './overviewStore';
import { ApplicationState } from './index';
import { loadLeaders, setLeaderCreateData } from './employeeStore';
import { LeaderCreate } from '../types/commonItems';
import { URLS } from '../constants/urls';
import { UtilsOverview } from '../utils/UtilsOverview';
import { MESSAGES } from '../constants/messages';

export interface DepartmentState {
  departmentSearchResult: Department[];
  editDepartment: Department;
  userDepartments: Department[];
  userEnrolledDepartments: Department[];
}

const defaultState: DepartmentState = {
  departmentSearchResult: null,
  editDepartment: null,
  userDepartments: [],
  userEnrolledDepartments: [],
};

export const setDepartmentSearchResult = actionCreator<Department[]>('SET_DEPARTMENT_SEARCH_RESULT');
export const setDepartmentEdit = actionCreator<Department>('SET_DEPARTMENT_EDIT');
export const setUserDepartments = actionCreator<Department[]>('SET_USER_DEPARTMENTS');
export const setUserEnrolledDepartments = actionCreator<Department[]>('SET_USER_ENROLLED_DEPARTMENTS');

export default (state = defaultState, action: any) => {
  if (isType(action, setDepartmentSearchResult)) {
    return {
      ...state,
      departmentSearchResult: action.payload,
    };
  }

  if (isType(action, setDepartmentEdit)) {
    return {
      ...state,
      editDepartment: action.payload,
    };
  }

  if (isType(action, setUserDepartments)) {
    return {
      ...state,
      userDepartments: action.payload,
    };
  }

  if (isType(action, setUserEnrolledDepartments)) {
    return {
      ...state,
      userEnrolledDepartments: action.payload,
    };
  }

  return state;
};

export const getDepartmentById = (id: string) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const employeeState = state.employees;
      const leaderCreate: LeaderCreate = employeeState.leaderCreate;

      const getDepartmentResponse: Department = await prodocApi.department.get(id);
      const { employeeEnrollment2LeaderId, employeeEnrollmentLeaderId } = getDepartmentResponse;
      const secondaryLeaders = employeeEnrollment2LeaderId || [];

      dispatch(setDepartmentEdit(getDepartmentResponse));

      if (!leaderCreate || !leaderCreate.leaderId) {
        dispatch(loadLeaders([employeeEnrollmentLeaderId, ...secondaryLeaders], getDepartmentResponse));
      }
    } catch (e) {
      dispatch(showErrorMessage({ message: e.message }));
    }
  };
};

export const enrollDepartmentLeader = (employeeId: string, departmentId: string) => {
  return async () => {
    try {
      if (!employeeId) return;

      const employeeEnrollments = await prodocApi.employeeEnrollment.getByEmployee(employeeId, true);
      const isEmployeeAlreadyEnrolled = !!employeeEnrollments.find(item => item.departmentId === departmentId);

      if (!isEmployeeAlreadyEnrolled) {
        const enrollmentData: EmployeeEnrollment = {
          employeeId,
          departmentId,
          position: Position.HeadOfDepartment,
          enrollmentDate: new Date(),
        };

        await prodocApi.employeeEnrollment.add(enrollmentData);
      }
    } catch (e) {}
  };
};

export const addDepartment = (data: Department) => {
  return async dispatch => {
    try {
      dispatch(setPageItemSaved(PAGES_ITEMS.DEPARTMENT_FORM, false));
      const { employeeEnrollmentLeaderId } = data;
      const department = await prodocApi.department.add(data);

      dispatch(enrollDepartmentLeader(employeeEnrollmentLeaderId, department.id));
      dispatch(showSuccessMessage({ message: 'Afdeling oprettet' }));
      dispatch(setPageItemSaved(PAGES_ITEMS.DEPARTMENT_FORM, true));
      dispatch(setLeaderCreateData(null));
      dispatch(navigateTo(UtilsNavigation.getHomeUrl()));
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    }
  };
};

export const updateDepartment = (data: Department) => {
  return async (dispatch, getState) => {
    try {
      const state: ApplicationState = getState();
      const { employeeEnrollmentLeaderId, id } = data;
      const { user } = state;

      await prodocApi.department.update(data);

      dispatch(enrollDepartmentLeader(employeeEnrollmentLeaderId, id));
      dispatch(showSuccessMessage({ message: 'Afdeling oplysninger opdateret' }));

      if (UtilsNavigation.shouldReturnToOverview()) {
        const updatedOverviewData = UtilsOverview.updateCompanyOverviewItemData(state.overview.data, data);

        dispatch(setOverviewData(updatedOverviewData));
        dispatch(goBack());
        return;
      }

      dispatch(navigateTo(UtilsNavigation.buildRoute(URLS.HOME, user.id)));
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    }
  };
};

export const loadUserEnrolledDepartments = () => {
  return async (dispatch, getState) => {
    try {
      const state: ApplicationState = getState();
      const { user } = state;
      const userEnrollments = await prodocApi.employeeEnrollment.getByEmployee(user.id, true);
      const departmentIds = userEnrollments.map(item => item.departmentId);
      const userDepartments = await prodocApi.department.getMany(departmentIds);

      dispatch(setUserEnrolledDepartments(userDepartments));
    } catch (e) {}
  };
};

export const setDepartmentLeader = (data: Department, enrollmentId: string) => {
  return async () => {
    try {
      const updatedDepartmentData = { ...data, employeeEnrollmentLeaderId: enrollmentId };

      await prodocApi.department.update(updatedDepartmentData);
    } catch (e) {}
  };
};

export const deleteDepartment = (id: string) => {
  return async dispatch => {
    try {
      await prodocApi.department.delete(id);

      dispatch(navigateToHome());
      dispatch(showSuccessMessage({ message: 'Afdeling slettet' }));
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    }
  };
};
