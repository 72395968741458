import React, { createRef, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import FullCalendar, { EventDropArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { Portal } from 'react-portal';
import { Select } from 'antd';
import moment from 'moment';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Button from 'antd/es/button';
import { DateSelectArg, EventClickArg } from '@fullcalendar/common';
import Interaction from '@fullcalendar/interaction';
import { useSelector } from 'react-redux';
import daLocale from '@fullcalendar/core/locales/da';
import classNames from 'classnames';

import CalendarSidebar from './CalendarSidebar/CalendarSidebar';
import CalendarMenu from './CalendarMenu/CalendarMenu';
import PageTitleContext from '../../context/PageTitleContext';
import { GoalCalendarItem } from '../../types/calendar';
import prodocApi, { CalendarItem, CalendarItemType, MedicinePassout } from '../../services/prodocApi';
import { ApplicationState } from '../../store';
import { CalendarState } from '../../store/calendarStore';
import { AGENDA_VIEWS_RANGE_MAP, CALENDAR_MEDICINE_BORDER_COLORS, CalendarViewTypes } from '../../constants/calendar';
import { useClickOutside } from '../../hooks/common-hooks';
import { useCalendarFilteredData } from '../../hooks/calendar-hooks';
import { CalendarTooltip, CalendarTooltipData } from './CalendarTooltip/CalendarTooltip';
import { UtilsCalendar } from '../../utils/UtilsCalendar';

import './Calendar.scss';

const { Option } = Select;

interface Props {
  data: GoalCalendarItem[];
  onCalendarItemCreate(itemId: CalendarItemType, eventData: DateSelectArg): void;
  onEventClick(event: GoalCalendarItem, eventArgs: EventClickArg): void;
}

export default function Calendar(props: Props) {
  const { onCalendarItemCreate, onEventClick } = props;
  const { calendarItems, setCalendarItems } = useCalendarFilteredData();
  const calendarContentRef = useRef();
  const calendarRef = createRef() as RefObject<any>;
  const calendarStore = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const [selectedEvent, setSelectedEvent] = useState<DateSelectArg>(null);
  const [currentType, setCurrentType] = useState(CalendarViewTypes.Week);
  const [currentView, setCurrentView] = useState(CalendarViewTypes.Week);
  const [currentTimeRange, setCurrentTimeRange] = useState(CalendarViewTypes.Week);
  const [tooltipData, setTooltipData] = useState<CalendarTooltipData | undefined>();
  const [_, setMenuEvent] = useState<MouseEvent>(null);
  const { setTitle } = React.useContext(PageTitleContext);
  const { citizensEnrollments, employeesEnrollments, medicineItems, medicineNavigationData } = calendarStore;
  const isMedicineView = currentType === CalendarViewTypes.Medicine || currentType === CalendarViewTypes.MedicineAgenda;
  const isAgendaView =
    currentView === CalendarViewTypes.AgendaDay ||
    currentView === CalendarViewTypes.AgendaMonth ||
    currentView === CalendarViewTypes.AgendaWeek;

  const headerToolbarOptions = { left: '', right: '' };

  useClickOutside(calendarContentRef, target => {
    const isMenuChildren = UtilsCalendar.isCalendarMenuChildren(target);

    if (!isMenuChildren) {
      setSelectedEvent(null);
    }
  });

  useEffect(() => {
    if (medicineNavigationData) {
      const medicineDate = moment(medicineNavigationData.date).toDate();

      handleDaySelect(medicineDate);
      setCurrentType(CalendarViewTypes.Medicine);
      setCurrentTimeRange(CalendarViewTypes.Day);
    }
  }, [medicineNavigationData]);

  const getItemColor = (item: CalendarItem) => {
    const { citizenEnrollmentId, employeeEnrollmentId } = item;

    if (citizenEnrollmentId) {
      return citizensEnrollments.find(item => item.id === citizenEnrollmentId).color;
    }

    return employeesEnrollments.find(item => item.id === employeeEnrollmentId).color;
  };

  const getBorderColor = (item: CalendarItem) => {
    const { fromDate, medicineState, note, entityId, done } = item as any;

    if ((!!entityId && !!note) || done) return CALENDAR_MEDICINE_BORDER_COLORS.TAKEN;

    if (!!entityId && !note) return '';

    if (!medicineState && Date.now() > fromDate.getTime()) return CALENDAR_MEDICINE_BORDER_COLORS.EXPIRED;

    if (medicineState?.medicinePassout === MedicinePassout.PassedOut) return CALENDAR_MEDICINE_BORDER_COLORS.TAKEN;

    if (medicineState?.medicinePassout === MedicinePassout.PassedOutFailed)
      return CALENDAR_MEDICINE_BORDER_COLORS.NOT_TAKEN;

    return '';
  };

  const eventsList = useMemo(() => {
    return calendarItems.map(item => {
      const { title, fromDate, toDate, allday, id, calendarItemType, done, note } = item;
      const showBorderColor =
        calendarItemType === CalendarItemType.Medicine || calendarItemType === CalendarItemType.PN || done;
      const itemColor = getItemColor(item);
      const isColorLight = itemColor ? UtilsCalendar.isCalendarColorLight(itemColor) : true; //;

      return {
        id,
        title: title,
        extendedProps: { item },
        start: fromDate,
        end: toDate,
        allDay: allday,
        className: classNames({
          light: isColorLight || isAgendaView,
          'main-calendar-item': !isAgendaView,
          'main-calendar-item-agenda': isAgendaView,
        }),
        color: getItemColor(item),
        borderColor: showBorderColor ? getBorderColor(item) : '',
        editable: calendarItemType !== CalendarItemType.Medicine && calendarItemType !== CalendarItemType.UseOfForce,
      };
    });
  }, [calendarItems, isAgendaView]);

  const handleDateSelect = (info: DateSelectArg) => {
    setMenuEvent(info.jsEvent);
    setSelectedEvent(info);
  };

  const handleDateUnSelect = () => {
    setMenuEvent(null);
  };

  const handleCalendarItemSelect = (itemId: CalendarItemType) => {
    onCalendarItemCreate(itemId, selectedEvent);
  };

  const handleEventClick = (eventArgs: EventClickArg) => {
    const searchedData = isMedicineView ? medicineItems : calendarItems;

    // @ts-ignore
    const calendarItemData = searchedData.find(item => {
      if (isMedicineView && item.calendarItemType === CalendarItemType.Medicine) {
        return item.start.getTime() === eventArgs.event.start.getTime();
      }

      return item.id === eventArgs.event.id;
    });

    const { isGoal, isEditable } = calendarItemData as any;

    if (!isEditable) return;
    if (isGoal && !isMedicineView) return;

    onEventClick(calendarItemData as any, eventArgs);
  };

  const handleEventMouseEnter = data => {
    const newTooltipData: CalendarTooltipData = {
      item: data.event._def.extendedProps.item,
      x: data.jsEvent.clientX,
      y: data.jsEvent.clientY,
    };

    setTooltipData(newTooltipData);
  };

  const handleEventMouseLeave = () => {
    setTooltipData(undefined);
  };

  const handleViewSelect = async view => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.changeView(view);
    setCurrentView(view);
  };

  const handleTimeRangeChange = timeRange => {
    const isAgenda = currentType === CalendarViewTypes.AgendaWeek || currentType === CalendarViewTypes.MedicineAgenda;
    const handledView = isAgenda ? AGENDA_VIEWS_RANGE_MAP[timeRange] : timeRange;

    handleViewSelect(handledView);
    setCurrentTimeRange(timeRange);
  };

  const handleTypeChange = type => {
    const calendarApi = calendarRef.current.getApi();

    setCurrentType(type);

    switch (type) {
      case CalendarViewTypes.AgendaWeek: {
        handleViewSelect(AGENDA_VIEWS_RANGE_MAP[currentTimeRange]);
        break;
      }
      case CalendarViewTypes.Notes: {
        // Reset calendar dates for notes view
        calendarApi.changeView(currentTimeRange);

        setTimeout(() => {
          setCurrentView(type);
        }, 50);
        break;
      }
      case CalendarViewTypes.Medicine: {
        handleViewSelect(currentTimeRange);
        break;
      }
      case CalendarViewTypes.MedicineAgenda: {
        handleViewSelect(AGENDA_VIEWS_RANGE_MAP[currentTimeRange]);
        break;
      }
      default: {
        const handledType = type === CalendarViewTypes.Week ? currentTimeRange : type;

        handleViewSelect(handledType);
      }
    }
  };

  const handleDatesSet = data => {
    const viewType = data.view.type;
    const isDateChanged =
      [CalendarViewTypes.Day, CalendarViewTypes.Week, CalendarViewTypes.Month].indexOf(viewType) >= 0;

    setTitle(data.view.title);

    if (isDateChanged) {
      setCurrentTimeRange(viewType);
    }

    if (currentView !== CalendarViewTypes.Notes) {
      // TODO Fix names mismatching
      const viewName = viewType.indexOf('Month') < 0 ? viewType.replace('day', 'time') : viewType;

      setCurrentView(viewName);

      if (calendarRef.current) {
        calendarRef.current.getApi().changeView(viewName);
      }
    }
  };

  const handleWeekSelect = date => {
    calendarRef.current.getApi().changeView('timeGridWeek', date);
  };

  const handleDaySelect = date => {
    calendarRef.current.getApi().changeView('timeGridDay', date);
  };

  const handleNavNext = () => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.next();
  };

  const handleNavPrev = () => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.prev();
  };

  const handleTodayBack = () => {
    const calendarApi = calendarRef.current.getApi();
    const calendarData = moment(calendarApi.getDate());
    const todayDate = moment();

    if (!calendarData.isSame(todayDate, 'day')) {
      calendarApi.today();
    }
  };

  const handleEventDrag = async (data: EventDropArg): Promise<void> => {
    const { oldEvent, delta } = data;
    const updatedFromDate = UtilsCalendar.getUpdatedDateByDelta(oldEvent.start, delta);
    const updatedToDate = UtilsCalendar.getUpdatedDateByDelta(oldEvent.end, delta);

    const event = eventsList.find(({ id }) => id === oldEvent.id);
    const eventCalendarItem: CalendarItem = event.extendedProps.item;
    const groupEvents = eventsList.filter(event => event.extendedProps.item.groupId === eventCalendarItem.groupId);

    const updatedCalendarItems = calendarItems.map(calendarItem => {
      const isUpdatedEvent = groupEvents.some(event => event.extendedProps.item.id === calendarItem.id);

      if (isUpdatedEvent) {
        const updatedCalendarItem = {
          ...calendarItem,
          fromDate: updatedFromDate,
          toDate: updatedToDate,
        };

        prodocApi.calendarItem.update(updatedCalendarItem);

        return updatedCalendarItem;
      }

      return calendarItem;
    });

    setCalendarItems(updatedCalendarItems);
  };

  const renderEventContent = (arg, createElement) => {
    const eventData = arg.event;
    let titleContent;

    if (isAgendaView) {
      const description = eventData.extendedProps.text;

      titleContent = (
        <div className="calendar-item-title__dscr">
          <span>{eventData.title}</span>
          {!!description && <div dangerouslySetInnerHTML={{ __html: description }} />}
        </div>
      );
    } else {
      titleContent = eventData.title;
    }

    return createElement('div', {}, <div className="calendar-item-title">{titleContent}</div>);
  };

  const displayedEventContent = isAgendaView ? renderEventContent : undefined;

  return (
    <>
      <div className="calendar-wrap">
        <CalendarSidebar />
        <div ref={calendarContentRef} className="calendar-wrap__container">
          <div className="calendar-controls">
            <Select value={currentType} onChange={handleTypeChange}>
              <Option value={CalendarViewTypes.Week}>Kalender</Option>
              <Option value={CalendarViewTypes.AgendaWeek}>Agenda</Option>
            </Select>
            <Select value={currentTimeRange} onChange={handleTimeRangeChange}>
              <Option value={CalendarViewTypes.Day}>Dag</Option>
              <Option value={CalendarViewTypes.Week}>Uge</Option>
              <Option value={CalendarViewTypes.Month}>Måned</Option>
            </Select>

            <Button onClick={handleNavPrev} icon={<LeftOutlined translate="true" />} />
            <Button className="calendar-controls__today-btn" onClick={handleTodayBack}>
              Idag
            </Button>
            <Button onClick={handleNavNext} icon={<RightOutlined translate="true" />} />
          </div>
          <FullCalendar
            datesSet={handleDatesSet}
            ref={calendarRef}
            events={eventsList}
            locale={daLocale}
            eventContent={displayedEventContent}
            plugins={[listPlugin, dayGridPlugin, timeGridPlugin, Interaction]}
            headerToolbar={headerToolbarOptions}
            initialView="timeGridWeek"
            weekNumbers
            height="100%"
            selectable
            nowIndicator
            eventClick={handleEventClick}
            eventMouseEnter={handleEventMouseEnter}
            eventMouseLeave={handleEventMouseLeave}
            select={handleDateSelect}
            unselect={handleDateUnSelect}
            navLinkWeekClick={handleWeekSelect}
            navLinkDayClick={handleDaySelect}
            dayMaxEvents
            selectMirror
            navLinks
            editable
            eventDurationEditable={false}
            rerenderDelay={10}
            droppable
            eventDrop={handleEventDrag}
          />
        </div>
        <CalendarMenu
          calendarViewType={currentType}
          event={selectedEvent}
          onCalendarItemSelect={handleCalendarItemSelect}
        />
      </div>
      <CalendarTooltip data={tooltipData} disabled={isAgendaView} />
    </>
  );
}
