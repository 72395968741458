import { Center } from '../services/prodocApi';
import { actionCreator, isType } from './Action';
import { showErrorMessage, showSuccessMessage } from './notificationStore';
import { loadLeaders } from './employeeStore';
import { LeaderCreate, OverViewItem } from '../types/commonItems';
import { PAGES_ITEMS, setPageItemSaved } from './pagesStore';
import { goBack, navigateTo, navigateToHome } from './navigationStore';
import { showCenterDepartmentCreateModal } from './modalStore';
import prodocApi from '../services/prodocApi';
import { UtilsNavigation } from '../utils/UtilsNavigation';
import { ApplicationState } from './index';
import { setOverviewData } from './overviewStore';
import { URLS } from '../constants/urls';
import { UtilsOverview } from '../utils/UtilsOverview';
import { MESSAGES } from '../constants/messages';

export interface CenterState {
  centerSearchResult: Center[];
  allCentersList: Center[];
  editCenter: Center;
}

const defaultState: CenterState = {
  centerSearchResult: null,
  editCenter: null,
  allCentersList: [],
};

export const setCenterSearchResult = actionCreator<Center[]>('SET_CENTER_SEARCH_RESULT');
export const setCenterEdit = actionCreator<Center>('SET_CENTER_EDIT');
export const setAllCentersList = actionCreator<Center[]>('SET_ALL_CENTERS_LIST');
export const setOverview = actionCreator<OverViewItem[]>('SET_OVERVIEW');

export default (state = defaultState, action: any) => {
  if (isType(action, setCenterSearchResult)) {
    return {
      ...state,
      centerSearchResult: action.payload,
    };
  }

  if (isType(action, setCenterEdit)) {
    return {
      ...state,
      editCenter: action.payload,
    };
  }

  if (isType(action, setAllCentersList)) {
    return {
      ...state,
      allCentersList: action.payload,
    };
  }

  if (isType(action, setOverview)) {
    return {
      ...state,
      overview: action.payload,
    };
  }

  return state;
};

export const getCenterById = (id: string) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const employeeState = state.employees;
      const leaderCreate: LeaderCreate = employeeState.leaderCreate;

      const getCenterResponse: Center = await prodocApi.center.get(id);

      const { employeeEnrollmentLeaderId, employeeEnrollment2LeaderId, employeeEnrollmentAdministrativeId } =
        getCenterResponse;

      dispatch(setCenterEdit(getCenterResponse));

      if (!leaderCreate || !leaderCreate.leaderId) {
        dispatch(
          loadLeaders(
            [employeeEnrollmentLeaderId, ...employeeEnrollment2LeaderId, ...(employeeEnrollmentAdministrativeId || [])],
            getCenterResponse
          )
        );
      }
    } catch (e) {
      dispatch(showErrorMessage({ message: e.message }));
    }
  };
};

export const addCenter = (data: Center) => {
  return async dispatch => {
    try {
      dispatch(setPageItemSaved(PAGES_ITEMS.CENTER_FORM, false));

      const createdCenter = await prodocApi.center.add(data);

      dispatch(showSuccessMessage({ message: 'Center oprettet' }));
      dispatch(setPageItemSaved(PAGES_ITEMS.CENTER_FORM, true));
      dispatch(showCenterDepartmentCreateModal(createdCenter.id));
    } catch (e) {
      if (!e.response) return;

      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    }
  };
};

export const updateCenter = (data: Center) => {
  return async (dispatch, getState) => {
    try {
      const state: ApplicationState = getState();
      const { user } = state;

      await prodocApi.center.update(data);

      dispatch(showSuccessMessage({ message: 'Center oplysninger opdateret' }));

      if (UtilsNavigation.shouldReturnToOverview()) {
        const updatedOverviewData = UtilsOverview.updateCompanyOverviewItemData(state.overview.data, data);

        dispatch(setOverviewData(updatedOverviewData));
        dispatch(goBack());
        return;
      }

      dispatch(navigateTo(UtilsNavigation.buildRoute(URLS.HOME, user.id)));
    } catch (e) {
      if (!e.response) return;

      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    }
  };
};

export const searchCenter = (searchStr: string) => {
  return async dispatch => {
    try {
      const centerSearchResult: Center[] = await prodocApi.center.search(searchStr);

      dispatch(setCenterSearchResult(centerSearchResult));
    } catch (e) {}
  };
};

export const getAllCenters = () => {
  return async dispatch => {
    try {
      const centers = await prodocApi.center.getMyCenters();

      dispatch(setAllCentersList(centers));
    } catch (e) {
      dispatch(showErrorMessage({ message: e.message }));
    }
  };
};

export const setCenterLeaderById = (centerId: string, employeeEnrollmentId: string) => {
  return async () => {
    try {
      const centerData = await prodocApi.center.get(centerId);
      const updatedCenterData: Center = { ...centerData, employeeEnrollmentLeaderId: employeeEnrollmentId };

      await prodocApi.center.update(updatedCenterData);
    } catch (e) {}
  };
};

export const deleteCenter = (id: string) => {
  return async dispatch => {
    try {
      await prodocApi.center.delete(id);

      dispatch(navigateToHome());
      dispatch(showSuccessMessage({ message: 'Center slettet' }));
    } catch (e) {
      dispatch(showErrorMessage({ message: MESSAGES.ERRORS.CENTER_HAS_DEPARTMENTS }));
    }
  };
};
