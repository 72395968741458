import moment from 'moment';
import prodocApi, {
  CalendarItem,
  CalendarItemType,
  CalendarStatus,
  CitizenEnrollment,
  CitizenEnrollmentResult,
  EmployeeEnrollment,
  EmployeeEnrollmentResult,
  Medicine,
  Note,
} from '../services/prodocApi';
import { actionCreator, isType } from './Action';
import { ApplicationState } from './index';
import { showSuccessMessage } from './notificationStore';
import { MESSAGES } from '../constants/messages';
import { PAGES_ITEMS, setPageItemLoading, setPageItemSaved } from './pagesStore';
import { CalendarItemsData, NotesFilterData, NotesFilterParams } from '../types/calendar';
import { UtilsCalendar } from '../utils/UtilsCalendar';
import { UtilsData } from '../utils/UtilsData';
import { UtilsMedicine } from '../utils/UtilsMedicine';
import { MedicineCalendarNavigationData } from '../types/medicine';
import { UtilsAuth } from '../utils/UtilsAuth';
import { CalendarItemsFilterValues } from '../constants/calendar';
import { defaultCalendarData } from '../data/calendar';

export interface CalendarState {
  citizensEnrollments: CitizenEnrollmentResult[];
  employeesEnrollments: EmployeeEnrollmentResult[];
  calendarItems: CalendarItem[];
  calendarNotes: Note[];
  selectedCalendarItem: CalendarItem;
  selectedCitizenEnrollments: string[];
  selectedEmployeeEnrollments: string[];
  notesFilterData: NotesFilterData;
  departmentId: string;
  medicineItems: Medicine[];
  medicineNavigationData: MedicineCalendarNavigationData | undefined;
  calendarItemsType: CalendarItemsFilterValues[];
  calendarData: CalendarItemsData;
}

const defaultState: CalendarState = {
  citizensEnrollments: [],
  employeesEnrollments: [],
  calendarItems: [],
  calendarNotes: [],
  selectedCalendarItem: null,
  selectedCitizenEnrollments: [],
  selectedEmployeeEnrollments: [],
  notesFilterData: { citizens: [], employees: [] },
  departmentId: '',
  medicineItems: [],
  calendarItemsType: [
    CalendarItemsFilterValues.Goals,
    CalendarItemsFilterValues.Medicine,
    CalendarItemsFilterValues.Notes,
    CalendarItemsFilterValues.Appointments,
    CalendarItemsFilterValues.UseOfForce,
    CalendarItemsFilterValues.Health,
    CalendarItemsFilterValues.TimeTrack,
    CalendarItemsFilterValues.Measurements,
    CalendarItemsFilterValues.Postings,
  ],
  medicineNavigationData: undefined,
  calendarData: defaultCalendarData,
};

const setCalendarCitizensEnrollments = actionCreator<CitizenEnrollment[]>('SET_CALENDAR_CITIZENS_ENROLLMENTS');
const setCalendarEmployeesEnrollments = actionCreator<EmployeeEnrollment[]>('SET_CALENDAR_EMPLOYEES_ENROLLMENTS');
const addCalendarItems = actionCreator<CalendarItem[]>('ADD_CALENDAR_ITEMS');
export const addCalendarNotes = actionCreator<Note[]>('ADD_CALENDAR_NOTES');
export const removeCalendarNotes = actionCreator<{ citizenId?: string; departmentId?: string }>(
  'REMOVE_CALENDAR_NOTES'
);
export const removeCalendarDepartmentNotes = actionCreator<string>('REMOVE_CALENDAR_DEPARTMENT_NOTES');
export const removeCalendarCitizenNotes = actionCreator<string>('REMOVE_CALENDAR_CITIZEN_NOTES');
export const setCalendarDepartmentId = actionCreator<string>('SET_CALENDAR_DEPARTMENT_ID');
export const setCalendarItems = actionCreator<CalendarItem[]>('SET_CALENDAR_ITEMS');
export const setCalendarNotes = actionCreator<Note[]>('SET_CALENDAR_NOTES');
export const removeCalendarItems = actionCreator<{ employeeId?: string; citizenId?: string; departmentId: string }>(
  'REMOVE_CALENDAR_ITEMS'
);
export const setSelectedCalendarItemAction = actionCreator<{ item: CalendarItem }>('SET_SELECTED_CALENDAR_ITEM_ACTION');
export const setSelectedCitizenEnrollmentsAction = actionCreator<string[]>('SET_SELECTED_CITIZEN_ENROLLMENTS');
export const setSelectedEmployeeEnrollmentsAction = actionCreator<string[]>('SET_SELECTED_EMPLOYEE_ENROLLMENTS');
export const setFilteredNotesAction = actionCreator<CalendarItem[]>('SET_FILTERED_NOTES');
export const setNotesFilterDataAction = actionCreator<NotesFilterData>('SET_NOTES_FILTER_DATA');
export const resetEmployeesCalendarItems = actionCreator('RESET_EMPLOYEES_CALENDAR_ITEMS');
export const resetCitizensCalendarItems = actionCreator('RESET_CITIZENS_CALENDAR_ITEMS');
export const addCalendarMedicineItems = actionCreator<CalendarItem[]>('ADD_CALENDAR_MEDICINE_ITEMS');
export const removeCalendarMedicineItems = actionCreator<string>('REMOVE_CALENDAR_MEDICINE_ITEMS');
export const setCalendarMedicineItems = actionCreator<CalendarItem[]>('SET_CALENDAR_MEDICINE_ITEMS');
export const setMedicineNavigationData = actionCreator<MedicineCalendarNavigationData | undefined>(
  'SET_CALENDAR_MEDICINE_NAVIGATION_DATA'
);
export const setCalendarItemsType = actionCreator<CalendarItemsFilterValues[]>('SET_CALENDAR_ITEMS_TYPE');

export const addCalendarItemsData = actionCreator<CalendarItemsData>('SET_CALENDAR_ITEMS_DATA');
export const resetCitizensCalendarItemsData = actionCreator('RESET_CITIZENS_CALENDAR_ITEMS_DATA');
export const resetEmployeesCalendarItemsData = actionCreator<string>('RESET_EMPLOYEES_CALENDAR_ITEMS_DATA');
export const removeCitizenCalendarItemsData = actionCreator<string>('REMOVE_CITIZEN_CALENDAR_ITEMS_DATA');
export const removeEmployeeCalendarItemsData = actionCreator<string>('REMOVE_EMPLOYEE_CALENDAR_ITEMS_DATA');
export const clearCalendarItemsData = actionCreator('CALENDAR_CLEAR_CALENDAR_ITEMS_DATA');
export const addAppointments = actionCreator<CalendarItem[]>('CALENDAR_ADD_APPOINTMENTS');

export default (state = defaultState, action: any) => {
  if (isType(action, setCalendarCitizensEnrollments)) {
    return {
      ...state,
      citizensEnrollments: action.payload,
    };
  }

  if (isType(action, setCalendarEmployeesEnrollments)) {
    return {
      ...state,
      employeesEnrollments: action.payload,
    };
  }

  if (isType(action, addCalendarItems)) {
    const newItems = [...action.payload, ...state.calendarItems];
    const filteredItems = UtilsData.removeDuplicatesFromArray(newItems, 'id');

    return {
      ...state,
      calendarItems: filteredItems,
    };
  }

  if (isType(action, addCalendarNotes)) {
    const newItems = [...state.calendarNotes, ...action.payload];
    const filteredItems = UtilsData.removeDuplicatesFromArray(newItems, 'id');

    return {
      ...state,
      calendarNotes: filteredItems,
    };
  }

  if (isType(action, removeCalendarNotes)) {
    const { citizenId, departmentId } = action.payload;
    let items = state.calendarNotes;

    if (citizenId) items = items.filter(item => item.citizenId !== citizenId || !!item.departmentId);
    if (departmentId) items = items.filter(item => item.departmentId !== departmentId || !!item.citizenId);

    return { ...state, calendarNotes: items };
  }

  if (isType(action, setCalendarNotes)) {
    return { ...state, calendarNotes: action.payload };
  }

  if (isType(action, removeCalendarDepartmentNotes)) {
    const departmentId = action.payload;
    const filteredItems = state.calendarNotes.filter(
      item => item.departmentId !== departmentId && !item.citizenEnrollmentId
    );

    return { ...state, calendarNotes: filteredItems };
  }

  if (isType(action, removeCalendarCitizenNotes)) {
    const citizenEnrollmentId = action.payload;
    const filteredItems = state.calendarNotes.filter(item => item.citizenEnrollmentId !== citizenEnrollmentId);

    return { ...state, calendarNotes: filteredItems };
  }

  if (isType(action, setCalendarItems)) {
    return {
      ...state,
      calendarItems: action.payload,
    };
  }

  if (isType(action, setCalendarDepartmentId)) {
    return {
      ...state,
      departmentId: action.payload,
      selectedCitizenEnrollments: [],
      selectedEmployeeEnrollments: [],
      calendarItems: [],
    };
  }

  if (isType(action, removeCalendarItems)) {
    const { citizenId, employeeId } = action.payload;

    if (citizenId) {
      return {
        ...state,
        calendarItems: state.calendarItems.filter(x => !(x.citizenEnrollmentId === citizenId)),
      };
    } else {
      return {
        ...state,
        calendarItems: state.calendarItems.filter(
          x => !(x.employeeEnrollmentId === employeeId && !x.citizenEnrollmentId)
        ),
      };
    }
  }

  if (isType(action, setSelectedCitizenEnrollmentsAction)) {
    return { ...state, selectedCitizenEnrollments: action.payload };
  }

  if (isType(action, setSelectedEmployeeEnrollmentsAction)) {
    return { ...state, selectedEmployeeEnrollments: action.payload };
  }

  if (isType(action, setSelectedCalendarItemAction)) {
    const newItem = action.payload.item ? { ...action.payload.item } : null;

    return { ...state, selectedCalendarItem: newItem };
  }

  if (isType(action, setNotesFilterDataAction)) {
    return { ...state, notesFilterData: action.payload };
  }

  if (isType(action, setFilteredNotesAction)) {
    return { ...state, filteredNotes: action.payload };
  }

  if (isType(action, resetEmployeesCalendarItems)) {
    return { ...state, calendarItems: state.calendarItems.filter(item => !!item.citizenEnrollmentId) };
  }

  if (isType(action, resetCitizensCalendarItems)) {
    return {
      ...state,
      calendarNotes: [],
      calendarItems: state.calendarItems.filter(item => !item.citizenEnrollmentId),
    };
  }

  if (isType(action, addCalendarMedicineItems)) {
    const newItems = [...action.payload, ...state.medicineItems];
    const filteredItems = UtilsData.removeDuplicatesFromArray(newItems, 'id');

    return { ...state, medicineItems: filteredItems };
  }

  if (isType(action, removeCalendarMedicineItems)) {
    return { ...state, medicineItems: state.medicineItems.filter(item => item.citizenEnrollmentId !== action.payload) };
  }

  if (isType(action, setCalendarMedicineItems)) {
    return { ...state, medicineItems: action.payload };
  }

  if (isType(action, setMedicineNavigationData)) {
    return { ...state, medicineNavigationData: action.payload };
  }

  if (isType(action, setCalendarItemsType)) {
    return { ...state, calendarItemsType: action.payload };
  }

  if (isType(action, addCalendarItemsData)) {
    const { appointments, notes, medicine, goals, health, timeTrack, measurements, useOfForce, postings } =
      action.payload;

    const updatedAppointments = UtilsData.removeDuplicatesFromArray(
      [...state.calendarData.appointments, ...appointments],
      'id'
    );
    const updatedNotes = UtilsData.removeDuplicatesFromArray([...state.calendarData.notes, ...notes], 'id');
    const updatedMedicine = UtilsData.removeDuplicatesFromArray([...state.calendarData.medicine, ...medicine], 'id');
    const updatedGoals = UtilsData.removeDuplicatesFromArray([...state.calendarData.goals, ...goals], 'id');
    const updatedHealth = UtilsData.removeDuplicatesFromArray([...state.calendarData.health, ...health], 'id');
    const updatedPostings = UtilsData.removeDuplicatesFromArray([...state.calendarData.postings, ...postings], 'id');

    const updatedTimeTrack = UtilsData.removeDuplicatesFromArray([...state.calendarData.timeTrack, ...timeTrack], 'id');
    const updatedMeasurements = UtilsData.removeDuplicatesFromArray(
      [...state.calendarData.measurements, ...measurements],
      'id'
    );
    const updatedUseOfForce = UtilsData.removeDuplicatesFromArray(
      [...state.calendarData.useOfForce, ...useOfForce],
      'id'
    );

    return {
      ...state,
      calendarData: {
        appointments: updatedAppointments,
        notes: updatedNotes,
        medicine: updatedMedicine,
        goals: updatedGoals,
        health: updatedHealth,
        timeTrack: updatedTimeTrack,
        measurements: updatedMeasurements,
        useOfForce: updatedUseOfForce,
        postings: updatedPostings,
      },
    };
  }

  if (isType(action, removeCitizenCalendarItemsData)) {
    const citizenEnrollmentId = action.payload;

    return {
      ...state,
      calendarData: {
        appointments: state.calendarData.appointments.filter(item => item.citizenEnrollmentId !== citizenEnrollmentId),
        notes: state.calendarData.notes.filter(item => item.citizenEnrollmentId !== citizenEnrollmentId),
        medicine: state.calendarData.medicine.filter(item => item.citizenEnrollmentId !== citizenEnrollmentId),
        goals: state.calendarData.goals.filter(item => item.citizenEnrollmentId !== citizenEnrollmentId),
        health: state.calendarData.health.filter(item => item.citizenEnrollmentId !== citizenEnrollmentId),
        timeTrack: state.calendarData.timeTrack.filter(item => item.citizenEnrollmentId !== citizenEnrollmentId),
        measurements: state.calendarData.measurements.filter(item => item.citizenEnrollmentId !== citizenEnrollmentId),
        useOfForce: state.calendarData.useOfForce.filter(item => item.citizenEnrollmentId !== citizenEnrollmentId),
        postings: state.calendarData.postings.filter(item => item.citizenEnrollmentId !== citizenEnrollmentId),
      },
    };
  }

  if (isType(action, removeEmployeeCalendarItemsData)) {
    const employeeEnrollmentId = action.payload;

    return {
      ...state,
      calendarData: {
        appointments: state.calendarData.appointments.filter(
          item => !(item.employeeEnrollmentId === employeeEnrollmentId && !item.citizenEnrollmentId)
        ),
        notes: state.calendarData.notes.filter(item => !(item.employeeEnrollmentId === employeeEnrollmentId)),
        medicine: state.calendarData.medicine.filter(
          item => !(item.employeeEnrollmentId === employeeEnrollmentId && !item.citizenEnrollmentId)
        ),
        goals: state.calendarData.goals.filter(
          item => !(item.employeeEnrollmentId === employeeEnrollmentId && !item.citizenEnrollmentId)
        ),
        health: state.calendarData.health.filter(
          item => !(item.employeeEnrollmentId === employeeEnrollmentId && !item.citizenEnrollmentId)
        ),
        timeTrack: state.calendarData.timeTrack.filter(
          item => !(item.employeeEnrollmentId === employeeEnrollmentId && !item.citizenEnrollmentId)
        ),
        measurements: state.calendarData.measurements.filter(
          item => !(item.employeeEnrollmentId === employeeEnrollmentId && !item.citizenEnrollmentId)
        ),
        useOfForce: state.calendarData.useOfForce.filter(
          item => !(item.employeeEnrollmentId === employeeEnrollmentId && !item.citizenEnrollmentId)
        ),
        postings: state.calendarData.postings.filter(
          item => !(item.employeeEnrollmentId === employeeEnrollmentId && !item.citizenEnrollmentId)
        ),
      },
    };
  }

  if (isType(action, resetCitizensCalendarItemsData)) {
    return {
      ...state,
      calendarData: {
        appointments: state.calendarData.appointments.filter(item => !item.citizenEnrollmentId),
        notes: state.calendarData.notes.filter(item => !item.citizenEnrollmentId),
        medicine: state.calendarData.medicine.filter(item => !item.citizenEnrollmentId),
        goals: state.calendarData.goals.filter(item => !item.citizenEnrollmentId),
        health: state.calendarData.health.filter(item => !item.citizenEnrollmentId),
        timeTrack: state.calendarData.timeTrack.filter(item => !item.citizenEnrollmentId),
        measurements: state.calendarData.measurements.filter(item => !item.citizenEnrollmentId),
        useOfForce: state.calendarData.useOfForce.filter(item => !item.citizenEnrollmentId),
        postings: state.calendarData.postings.filter(item => !item.citizenEnrollmentId),
      },
    };
  }

  if (isType(action, resetEmployeesCalendarItemsData)) {
    return {
      ...state,
      calendarData: {
        appointments: state.calendarData.appointments.filter(item => !item.employeeEnrollmentId),
        notes: state.calendarData.notes.filter(item => !item.employeeEnrollmentId),
        medicine: state.calendarData.medicine.filter(item => !item.employeeEnrollmentId),
        goals: state.calendarData.goals.filter(item => !item.employeeEnrollmentId),
        health: state.calendarData.health.filter(item => !item.employeeEnrollmentId),
        timeTrack: state.calendarData.timeTrack.filter(item => !item.employeeEnrollmentId),
        measurements: state.calendarData.measurements.filter(item => !item.employeeEnrollmentId),
        useOfForce: state.calendarData.useOfForce.filter(item => !item.employeeEnrollmentId),
        postings: state.calendarData.postings.filter(item => !item.employeeEnrollmentId),
      },
    };
  }

  if (isType(action, addAppointments)) {
    return {
      ...state,
      calendarData: {
        ...state.calendarData,
        appointments: [...state.calendarData.appointments, ...action.payload],
      },
    };
  }

  if (isType(action, clearCalendarItemsData)) {
    return { ...state, calendarData: defaultCalendarData };
  }

  return state;
};

export const loadCalendarCitizensEnrollments = () => {
  return async dispatch => {
    try {
      const userId = UtilsAuth.getEmployeeId();
      const userEnrollments = await prodocApi.employeeEnrollment.getByEmployee(userId, true);
      const employeeDepartmentsIds = userEnrollments.map(item => item.departmentId);

      const citizens = await prodocApi.citizenEnrollment.getManyByDepartments({
        departmentIds: employeeDepartmentsIds,
      });
      const combinedCitizens = [].concat(...citizens);
      const filteredCitizens = combinedCitizens.filter(
        (thing, index, self) => index === self.findIndex(t => t.id === thing.id)
      );

      dispatch(setCalendarCitizensEnrollments(filteredCitizens));
    } catch (e) {}
  };
};

export const loadCalendarEmployeesEnrollments = () => {
  return async (dispatch, getState) => {
    try {
      const state: ApplicationState = getState();
      const userState = state.user;

      const userEnrollments = await prodocApi.employeeEnrollment.getByEmployee(userState.id, true);
      const employeeDepartmentsIds = userEnrollments.map(item => item.departmentId);

      const employees = await prodocApi.employeeEnrollment.getManyByDepartments({
        departmentIds: employeeDepartmentsIds,
      });

      const combinedEmployees = [].concat(...employees);
      const filteredEmployees = combinedEmployees.filter(
        (thing, index, self) => index === self.findIndex(t => t.id === thing.id)
      );

      dispatch(setCalendarEmployeesEnrollments(filteredEmployees));
    } catch (e) {}
  };
};

export const addCalendarItem = (
  data: CalendarItem,
  citizensEnrollmentsIds: string[],
  employeesEnrollmentsIds: string[]
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setPageItemSaved(PAGES_ITEMS.CALENDAR_ITEM, false));
      dispatch(setPageItemLoading(PAGES_ITEMS.CALENDAR_ITEM, true));

      const state: ApplicationState = getState();
      const { user, calendar, common } = state;
      const { id: currentUserId } = user;
      const { userEnrollments } = common;
      const { departmentId, selectedEmployeeEnrollments, selectedCitizenEnrollments, employeesEnrollments } = calendar;
      const activeEnrollment = userEnrollments.find(item => item.departmentId === departmentId);
      const { id, employeeId } = activeEnrollment;

      const citizensItems = citizensEnrollmentsIds.map(item => ({
        ...data,
        employeeId,
        citizenId: null,
        employeeEnrollmentId: id,
        citizenEnrollmentId: item,
        departmentId,
        isPrivate: false,
        isAccepted: true,
        isEditable: true,
      }));
      const employeesItems = employeesEnrollmentsIds.map(item => {
        const isCurrentUser = !!employeesEnrollments.find(
          listItem => listItem.id === item && listItem.employeeId === currentUserId
        );
        const status = isCurrentUser ? CalendarStatus.Accepted : CalendarStatus.Unanswered;

        return {
          ...data,
          employeeEnrollmentId: item,
          citizenId: null,
          citizenEnrollmentId: '',
          departmentId,
          employeeId,
          // Auto accept an appointment for current user
          isAccepted: isCurrentUser,
          calendarStatus: status,
          isEditable: true,
        };
      });

      const res = await prodocApi.calendarItem.add([...citizensItems, ...employeesItems]);
      let itemsToDisplay = [];

      employeesEnrollmentsIds.forEach(eEnrollmentId => {
        if (selectedEmployeeEnrollments.includes(eEnrollmentId)) {
          const items = res.filter(item => item.employeeEnrollmentId === eEnrollmentId && !item.citizenEnrollmentId);

          itemsToDisplay = [...itemsToDisplay, ...items];
        }
      });

      citizensEnrollmentsIds.forEach(cEnrollmentId => {
        if (selectedCitizenEnrollments.includes(cEnrollmentId)) {
          const items = res.filter(item => item.citizenEnrollmentId === cEnrollmentId);

          itemsToDisplay = [...itemsToDisplay, ...items];
        }
      });

      dispatch(addAppointments(itemsToDisplay));
      dispatch(showSuccessMessage({ message: 'Aftale oprettet' }));
      dispatch(setPageItemSaved(PAGES_ITEMS.CALENDAR_ITEM, true));
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    } finally {
      dispatch(setPageItemLoading(PAGES_ITEMS.CALENDAR_ITEM, false));
    }
  };
};

export const updateCalendarItem = (data: CalendarItem, citizensEnrollmentsIds: string[], employeesIds: string[]) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setPageItemSaved(PAGES_ITEMS.CALENDAR_ITEM, false));
      dispatch(setPageItemLoading(PAGES_ITEMS.CALENDAR_ITEM, true));

      const state: ApplicationState = getState();
      const { calendar } = state;
      const { selectedCitizenEnrollments, selectedEmployeeEnrollments, departmentId } = calendar;
      const { groupId, employeeEnrollmentId: eEnrollmentId } = data;
      const itemsForDelete: CalendarItem[] = [];
      const itemsForCreate: CalendarItem[] = [];
      const itemCopies = await prodocApi.calendarItem.getManyByGroupId(groupId);
      const appointmentsCopies = itemCopies.filter(item => item.calendarItemType !== CalendarItemType.Note);

      const itemsForUpdate = appointmentsCopies.filter(item => {
        const { citizenEnrollmentId, employeeEnrollmentId } = item;

        if (!citizensEnrollmentsIds.includes(citizenEnrollmentId) && !employeesIds.includes(employeeEnrollmentId)) {
          itemsForDelete.push(item);

          return false;
        }

        return true;
      });

      citizensEnrollmentsIds.forEach(item => {
        const isNewItem = !appointmentsCopies.find(copy => copy.citizenEnrollmentId === item);

        if (isNewItem) {
          itemsForCreate.push({
            ...data,
            id: '',
            citizenId: null,
            citizenEnrollmentId: item,
            employeeEnrollmentId: eEnrollmentId,
            isPrivate: false,
          });
        }
      });

      employeesIds.forEach(item => {
        const isNewItem = !appointmentsCopies.find(copy => copy.employeeEnrollmentId === item);

        if (isNewItem) {
          itemsForCreate.push({
            ...data,
            id: '',
            citizenId: null,
            citizenEnrollmentId: '',
            employeeEnrollmentId: item,
            isAccepted: false,
            calendarStatus: CalendarStatus.Unanswered,
          });
        }
      });

      // Update items
      await Promise.all(
        itemsForUpdate.map(async item => {
          const { citizenEnrollmentId, employeeEnrollmentId, citizenId, employeeId, id, isPrivate } = item;

          return await prodocApi.calendarItem.update({
            ...data,
            id,
            citizenId: null,
            employeeId,
            citizenEnrollmentId: citizenEnrollmentId,
            employeeEnrollmentId: employeeEnrollmentId,
            calendarStatus: item.calendarStatus,
            isAccepted: item.isAccepted,
            isPrivate: employeeEnrollmentId ? isPrivate : false,
          });
        })
      );

      // Delete items
      itemsForDelete.forEach(item => {
        prodocApi.calendarItem.delete(item.id);
      });

      if (itemsForCreate.length) prodocApi.calendarItem.addToGroup(groupId, itemsForCreate);

      // Load calendarItems
      selectedCitizenEnrollments.forEach(citizenEnrollmentId => {
        dispatch(reloadCalendarItemsForCitizen(citizenEnrollmentId));
      });
      selectedEmployeeEnrollments.forEach(employeeEnrollmentId => {
        dispatch(reloadCalendarItemsForEmployee(employeeEnrollmentId));
      });

      dispatch(showSuccessMessage({ message: 'Kalender aftale opdateret' }));
      dispatch(setPageItemSaved(PAGES_ITEMS.CALENDAR_ITEM, true));
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    } finally {
      dispatch(setPageItemLoading(PAGES_ITEMS.CALENDAR_ITEM, false));
    }
  };
};

export const deleteCalendarItem = (id: string) => {
  return async dispatch => {
    try {
      await prodocApi.calendarItem.delete(id);

      dispatch(setSelectedCalendarItemAction({ item: null }));
      dispatch(reloadCalendarItems());
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    }
  };
};

export const deleteParticipantsCalendarItems = (groupId: string) => {
  return async dispatch => {
    try {
      const existingItems = await prodocApi.calendarItem.getManyByGroupId(groupId);

      for (const item of existingItems) {
        await prodocApi.calendarItem.delete(item.id);
      }

      dispatch(setSelectedCalendarItemAction({ item: null }));
      dispatch(reloadCalendarItems());
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    }
  };
};

export const changeAppointmentStatus = (data: CalendarItem, newStatus: CalendarStatus) => {
  return async dispatch => {
    try {
      const isDeclined = newStatus === CalendarStatus.Declined;
      const updatedItem = {
        ...data,
        isAccepted: newStatus === CalendarStatus.Accepted,
        calendarStatus: newStatus,
      };
      await prodocApi.calendarItem.update(updatedItem);
      const itemForUpdate = isDeclined ? null : updatedItem;

      dispatch(setSelectedCalendarItemAction({ item: itemForUpdate }));
      dispatch(getCalendarItemsEmployee(data.employeeEnrollmentId));
      dispatch(showSuccessMessage({ message: 'Kalender aftale opdateret' }));
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    }
  };
};

export const loadMedicineCalendarItems = (citizenId: string) => {
  return async dispatch => {
    try {
      const res = await prodocApi.medicine.getManyByCitizenWithoutCalendarItem(citizenId);
      const calendarItems = UtilsMedicine.createCalendarItemsFromMedicineItems(res);

      dispatch(addCalendarMedicineItems(calendarItems));
    } catch (e) {}
  };
};

export const getCalendarItemsCitizen = (citizenEnrollmentId: string, departmentId: string) => {
  return async dispatch => {
    try {
      const res = await prodocApi.calendarItem.getFromCitizenEnrollment(citizenEnrollmentId);
      const filteredItems = res.filter(item => {
        const { calendarItemType, healthAppointment, calendarStatus } = item;

        if (calendarItemType === CalendarItemType.Note && healthAppointment) return true;

        if (calendarStatus === CalendarStatus.Declined) return false;

        if (calendarItemType === CalendarItemType.PN) return false;

        return calendarItemType !== CalendarItemType.Note;
      });
      const notesItems = res.filter(item => item.calendarItemType === CalendarItemType.Note);
      const pnItems = res.filter(item => item.calendarItemType === CalendarItemType.PN);
      const citizenGoals = await prodocApi.goal.getByCitizenEnrollment(citizenEnrollmentId, true);
      const goalsItems = UtilsCalendar.createGoalsCalendarItems(citizenGoals, departmentId);

      dispatch(addCalendarNotes(notesItems as any[]));
      dispatch(addCalendarItems([...filteredItems, ...goalsItems]));
      dispatch(addCalendarMedicineItems(pnItems));
    } catch (e) {}
  };
};

export const getDepartmentNotes = (departmentId: string) => {
  return async dispatch => {
    try {
      const res = await prodocApi.note.getManyByDepartment(departmentId);

      dispatch(addCalendarNotes(res));
    } catch (e) {}
  };
};

export const getCalendarItemsEmployee = (employeeEnrollmentId: string) => {
  return async dispatch => {
    try {
      const res = await prodocApi.calendarItem.getFromEmployeeEnrollment(employeeEnrollmentId);
      const filteredItems = res.filter(item => {
        const { calendarStatus, citizenEnrollmentId, calendarItemType } = item;

        return (
          calendarStatus !== CalendarStatus.Declined &&
          !citizenEnrollmentId &&
          calendarItemType !== CalendarItemType.Note &&
          calendarItemType !== CalendarItemType.PN
        );
      });

      dispatch(addCalendarItems(filteredItems));
    } catch (e) {}
  };
};

export const loadCalendarSidebarCitizens = () => {
  return async (dispatch, getState) => {
    try {
      const state: ApplicationState = getState();
      const { common } = state;
      const { userEnrollments } = common;
      let citizenEnrollments = [];

      const departmentIds = userEnrollments.map(item => item.departmentId);

      const citizens = await prodocApi.citizenEnrollment.getManyByDepartments({ departmentIds });

      citizenEnrollments = citizenEnrollments.concat(citizens);

      dispatch(setCalendarCitizensEnrollments(citizenEnrollments));
    } catch (e) {}
  };
};

export const loadCalendarSidebarEmployees = () => {
  return async (dispatch, getState) => {
    try {
      const state: ApplicationState = getState();
      const { common } = state;
      const { userEnrollments } = common;
      var departmentIds = userEnrollments.map(x => x.departmentId);
      const employeesEnrollments = await prodocApi.employeeEnrollment.getManyByDepartments({
        departmentIds,
      });

      dispatch(setCalendarEmployeesEnrollments(employeesEnrollments));
    } catch (e) {}
  };
};

export const filterNotes = (data: NotesFilterParams) => {
  return async dispatch => {
    try {
      const { citizens = [], employees = [] } = data;

      if (citizens.length || employees.length) {
        let items = [];

        if (employees.length) {
          items = await prodocApi.calendarItem.getFromEmployeeEnrollment(employees[0]);
        }

        if (citizens.length) {
        }

        const sortedNotes = UtilsCalendar.sortNotes(items);

        dispatch(setFilteredNotesAction(sortedNotes));
      } else {
        dispatch(resetNotesFilter());
      }
    } catch (e) {}
  };
};

export const loadNotesFilterData = (departmentIds: string[]) => {
  return async dispatch => {
    try {
      if (departmentIds && departmentIds.length) {
        const employees = await prodocApi.employeeEnrollment.getManyByDepartments({ departmentIds });
        const filteredEmployees = UtilsData.removeDuplicatesFromArray(employees, 'id');
        const filterData: NotesFilterData = { citizens: [], employees: filteredEmployees };

        dispatch(setNotesFilterDataAction(filterData));
      } else {
        dispatch(setNotesFilterDataAction({ citizens: [], employees: [] }));
      }
    } catch (e) {}
  };
};

export const exportNotesToPdf = () => {
  return (dispatch, getState) => {
    try {
      const state: ApplicationState = getState();
      const { calendar } = state;
      // const { filteredNotes, notesFilterData } = calendar;
      // const { employees } = notesFilterData;
      //
      // UtilsCalendar.exportNotesToPdf(filteredNotes, employees);
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    }
  };
};

export const resetNotesFilter = () => {
  return dispatch => {
    dispatch(setFilteredNotesAction([]));
    dispatch(setNotesFilterDataAction({ citizens: [], employees: [] }));
  };
};

export const reloadCalendarItems = () => {
  return (dispatch, getState) => {
    const state: ApplicationState = getState();
    const { calendar } = state;
    const { selectedCitizenEnrollments, selectedEmployeeEnrollments, departmentId } = calendar;

    dispatch(clearCalendarItemsData());

    selectedCitizenEnrollments.forEach(item => {
      dispatch(loadCitizenEnrollmentCalendarItems(item));
    });

    selectedEmployeeEnrollments.forEach(item => {
      dispatch(loadEmployeeEnrollmentCalendarItems(item));
    });
  };
};

export const createPnCalendarItem = (data: Medicine) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setPageItemSaved(PAGES_ITEMS.CALENDAR_ITEM, false));
      dispatch(setPageItemLoading(PAGES_ITEMS.CALENDAR_ITEM, true));

      const state: ApplicationState = getState();
      const { calendar } = state;
      const { selectedCitizenEnrollments } = calendar;
      // PN items needs to be 30 minutes after a selected date
      const fromDate = moment(data.fromDate).add(30, 'minutes').toDate();
      const toDate = moment(fromDate).add(30, 'minutes').toDate();
      const requestData: Medicine = {
        ...data,
        id: '',
        fromDate,
        toDate,
      };

      await prodocApi.medicine.add(false, requestData);

      if (selectedCitizenEnrollments.indexOf(data.citizenEnrollmentId) >= 0) {
        dispatch(reloadCalendarItemsForCitizen(data.citizenEnrollmentId));
      }

      dispatch(setPageItemSaved(PAGES_ITEMS.CALENDAR_ITEM, true));
      dispatch(showSuccessMessage({ message: 'PN Medicin udleveret' }));
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
      dispatch(setPageItemSaved(PAGES_ITEMS.CALENDAR_ITEM, false));
    } finally {
      dispatch(setPageItemLoading(PAGES_ITEMS.CALENDAR_ITEM, false));
    }
  };
};

export const evaluatePnCalendarItem = (data: Medicine) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setPageItemSaved(PAGES_ITEMS.CALENDAR_ITEM, false));
      dispatch(setPageItemLoading(PAGES_ITEMS.CALENDAR_ITEM, true));

      const state: ApplicationState = getState();
      const { calendar } = state;
      const { selectedCitizenEnrollments } = calendar;
      const requestData: Medicine = {
        ...data,
        toDate: moment(data.fromDate).add(30, 'minutes').toDate(),
      };

      await prodocApi.medicine.update(false, requestData);

      if (selectedCitizenEnrollments.includes(data.citizenEnrollmentId)) {
        dispatch(reloadCalendarItemsForCitizen(data.citizenEnrollmentId));
      }

      dispatch(setPageItemSaved(PAGES_ITEMS.CALENDAR_ITEM, true));
      dispatch(showSuccessMessage({ message: 'Saved' }));
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
      dispatch(setPageItemSaved(PAGES_ITEMS.CALENDAR_ITEM, false));
    } finally {
      dispatch(setPageItemLoading(PAGES_ITEMS.CALENDAR_ITEM, false));
    }
  };
};

export const loadCitizenEnrollmentCalendarItems = (citizenEnrollmentId: string) => {
  return async (dispatch, getState) => {
    try {
      const state: ApplicationState = getState();
      const { citizensEnrollments } = state.calendar;
      const activeEnrollment = citizensEnrollments.find(item => item.id === citizenEnrollmentId);

      const calendarItems = await prodocApi.calendarItem.getFromCitizenEnrollment(citizenEnrollmentId);
      const medicineItems = await prodocApi.medicine.getManyByCitizenWithoutCalendarItem(activeEnrollment.citizenId);

      const data: CalendarItemsData = UtilsCalendar.convertCalendarItemsToCalendarData(calendarItems, medicineItems);

      dispatch(addCalendarItemsData(data));
    } catch (e) {}
  };
};

export const loadEmployeeEnrollmentCalendarItems = (employeeEnrollmentId: string) => {
  return async dispatch => {
    const calendarItems = await prodocApi.calendarItem.getFromEmployeeEnrollment(employeeEnrollmentId);

    const appointments = calendarItems.filter(({ calendarItemType, calendarStatus, citizenEnrollmentId }) => {
      return (
        calendarItemType === CalendarItemType.Normal &&
        calendarStatus !== CalendarStatus.Declined &&
        !citizenEnrollmentId
      );
    });

    const timeTrack = calendarItems.filter(({ calendarItemType, calendarStatus, citizenEnrollmentId }) => {
      return (
        calendarItemType === CalendarItemType.TimeTrack &&
        calendarStatus !== CalendarStatus.Declined &&
        !citizenEnrollmentId
      );
    });

    const data: CalendarItemsData = { ...defaultCalendarData, appointments, timeTrack };

    dispatch(addCalendarItemsData(data));
  };
};

export const reloadCalendarItemsForCitizen = (cEnrollmentId: string) => {
  return dispatch => {
    dispatch(resetCitizensCalendarItemsData(cEnrollmentId));
    dispatch(loadCitizenEnrollmentCalendarItems(cEnrollmentId));
  };
};

export const reloadCalendarItemsForEmployee = (eEnrollmentId: string) => {
  return dispatch => {
    dispatch(resetEmployeesCalendarItemsData(eEnrollmentId));
    dispatch(loadEmployeeEnrollmentCalendarItems(eEnrollmentId));
  };
};
