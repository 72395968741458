import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CalendarItem, CalendarItemType } from '../../../services/prodocApi';
import { calendarItemDefault } from '../../../services/defaults';
import { ApplicationState } from '../../../store';
import { CalendarState } from '../../../store/calendarStore';
import { useParticipantsRequest } from '../../../hooks/calendar-hooks';

import './CalendarTooltip.scss';

export interface CalendarTooltipData {
  x: number;
  y: number;
  item: CalendarItem;
}

interface Props {
  data?: CalendarTooltipData;
  disabled: boolean;
}

export const CalendarTooltip: React.FC<Props> = React.memo(
  props => {
    const { data, disabled } = props;
    const { t } = useTranslation();
    const { getAppointmentParticipants } = useParticipantsRequest();
    const [citizensNames, setCitizensNames] = useState<string>('');
    const [employeesNames, setEmployeesNames] = useState<string>('');
    const calendarState = useSelector<ApplicationState, CalendarState>(state => state.calendar);
    const { item = calendarItemDefault(), x = 0, y = 0 } = data || {};
    const wrapperRef = useRef();
    const { citizensEnrollments, employeesEnrollments } = calendarState;
    const { note, calendarItemType, id: calendarItemId, citizenEnrollmentId, employeeEnrollmentId } = item;

    const loadParticipantsNames = async (): Promise<void> => {
      const { citizens, employees } = await getAppointmentParticipants(data.item);

      const filteredCitizenEnrollments = citizensEnrollments.filter(item => citizens.includes(item.id));
      const citizensEnrollmentsNames = filteredCitizenEnrollments
        .map(({ firstname, lastname }) => {
          return `${firstname} ${lastname}`;
        })
        .join(', ');

      const filteredEmployeesEnrollments = employeesEnrollments.filter(item => employees.includes(item.id));
      const employeesEnrollmentsNames = filteredEmployeesEnrollments
        .map(({ firstname, lastname }) => {
          return `${firstname} ${lastname}`;
        })
        .join(', ');

      setCitizensNames(citizensEnrollmentsNames);
      setEmployeesNames(employeesEnrollmentsNames);
    };

    useEffect(() => {
      if (!disabled && data?.item.calendarItemType === CalendarItemType.Normal && data?.item.groupId) {
        loadParticipantsNames();
      }
    }, [disabled, data?.item.id]);

    const displayedCitizensName = useMemo(() => {
      if (citizensNames) return citizensNames;

      const citizenEnrollment = citizensEnrollments.find(item => citizenEnrollmentId === item.id);

      return citizenEnrollment ? `${citizenEnrollment.firstname} ${citizenEnrollment.lastname}` : '';
    }, [calendarItemId, citizensNames]);

    const displayedEmployeesName = useMemo(() => {
      if (employeesNames) return employeesNames;

      const employeeEnrollment = employeesEnrollments.find(item => employeeEnrollmentId === item.id);

      return employeeEnrollment ? `${employeeEnrollment.firstname} ${employeeEnrollment.lastname}` : '';
    }, [calendarItemId, employeesNames]);

    const handledX = useMemo(() => {
      const rightEdgePos = x + 250;

      if (rightEdgePos > window.innerWidth) return x - (rightEdgePos - window.innerWidth) - 20;

      return x;
    }, [x]);

    if (!data || disabled) return null;

    return createPortal(
      <div ref={wrapperRef} className="calendar-tooltip" style={{ left: handledX, top: y - 20 }}>
        <div className="calendar-tooltip__item">
          <span className="calendar-tooltip__name">Type: </span>
          <span className="calendar-tooltip__value">{t(calendarItemType)}</span>
        </div>
        <div className="calendar-tooltip__item">
          <span className="calendar-tooltip__name">Borger: </span>
          <span className="calendar-tooltip__value">{displayedCitizensName}</span>
        </div>
        <div className="calendar-tooltip__item">
          <span className="calendar-tooltip__name">Medarbejder: </span>
          <span className="calendar-tooltip__value">{displayedEmployeesName}</span>
        </div>
        <div className="calendar-tooltip__item">
          <span className="calendar-tooltip__name">
            {calendarItemType === CalendarItemType.Measurement ? 'Bemærkning: ' : 'Beskrivelse: '}
          </span>
          <span className="calendar-tooltip__value" dangerouslySetInnerHTML={{ __html: note }} />
        </div>
      </div>,
      document.getElementsByTagName('body')[0],
    );
  },
  (prevProps, nextProps) =>
    prevProps.data?.x === nextProps.data?.x &&
    prevProps.data?.y === nextProps.data?.y &&
    prevProps.data?.item.id === nextProps.data?.item.id,
);
