import React, { useEffect, useMemo, useState } from 'react';
import TextInput from '../../../_shared/_controls/TextInput/TextInput';
import DatePicker from '../../../_shared/_controls/DatePicker/DatePicker';
import { useMedicineSearch } from '../../../../hooks/medicine-hooks';
import Autocomplete, { AcOption } from '../../../_shared/Autocomplete/Autocomplete';
import {
  DoseUnit,
  Medicine,
  MedicineType,
  Product,
  StringProductListKeyValuePair,
} from '../../../../services/prodocApi';
import SelectMenu, { Option } from '../../../_shared/_controls/SelectMenu/SelectMenu';
import { UtilsData } from '../../../../utils/UtilsData';
import moment from 'moment';
import { MESSAGES } from '../../../../constants/messages';
import { useEnumOptionsTranslation } from '../../../../hooks/common-hooks';
import { UtilsMedicine } from '../../../../utils/UtilsMedicine';

interface Props {
  isActive: boolean;
  isDosesCorrect: boolean;
  incDosesCorrect: boolean;
  decDosesCorrect: boolean;
  data: Medicine;
  onChange(data: Medicine): void;
}

export const MedicineOrdinationGroup: React.FC<Props> = (props) => {
  const { onChange, data, incDosesCorrect, isDosesCorrect, decDosesCorrect, isActive } = props;
  const { translateEnumOptions } = useEnumOptionsTranslation();
  const { searchMedicine, clearResults, results, isLoading } = useMedicineSearch();
  const [medicineValue, setMedicineValue] = useState('');
  const [dosageValue, setDosageValue] = useState('');
  const [dosageOptions, setDosageOptions] = useState<Product[]>([]);
  const [formData, setFormData] = useState<Medicine>(UtilsData.getMedicineDefaults());

  const {
    fromDate,
    toDate,
    medicineType,
    doseUnit,
    dailyDose,
    maxDailyDose,
    incDecStartDose,
    incDecEndDose,
    incDecEveryX,
    incDecDose,
  } = formData;
  const doseError = isDosesCorrect ? '' : MESSAGES.MEDICINE_DAILY_DOSE_ERROR;
  const incDoseError = incDosesCorrect ? '' : MESSAGES.MEDICINE_INC_DOSE_ERROR;
  const decDoseError = decDosesCorrect ? '' : MESSAGES.MEDICINE_DEC_DOSE_ERROR;
  const isIncreasing = medicineType === MedicineType.Increasing;
  const isDecreasing = medicineType === MedicineType.Decreasing;
  const isPn = medicineType === MedicineType.PN;
  const isSchema = medicineType === MedicineType.BySchema;
  const disableIncDecFields = !isIncreasing && !isDecreasing;
  const disableEndDate = isIncreasing || isDecreasing;
  const incDecPrefix = useMemo(() => {
    return UtilsMedicine.getMedicineIncDecPrefix(isIncreasing, isDecreasing);
  }, [isDecreasing, isIncreasing]);
  const notFoundMedicineStatus = isLoading ? 'Søger' : undefined;
  const doseUnitOptions = useMemo(() => {
    return translateEnumOptions(DoseUnit).sort(((a, b) => a.value.localeCompare(b.value)));
  }, []);

  const resetForm = () => {
    setFormData(UtilsData.getMedicineDefaults());
    setMedicineValue('');
    setDosageValue('');
    clearResults();
  };

  useEffect(() => {
    if (!isActive) resetForm();
  }, [isActive]);

  useEffect(() => {
    if (isLoading && dosageOptions.length) {
      setDosageOptions([]);
      setDosageValue('');
    }
  }, [isLoading]);

  useEffect(() => {
    const dataProduct = data.product;

    setFormData(data);

    if (dataProduct) {
      setMedicineValue(`${dataProduct?.navn} ${dataProduct?.styrke}`);
      setDosageValue(dataProduct?.pakning);
    }
  }, [data.id]);

  const handleFieldChange = (value: any, name: string) => {
    const newData = { ...formData, [name]: value };
    setFormData(newData);
    onChange(newData);
  };

  const handleMedicineSelect = (value) => {
    const selectedOption: StringProductListKeyValuePair = results.find(item => item.value[0].navn === value);
    const selectedProduct = selectedOption.value[0];
    const { navn, styrke, varenummer } = selectedProduct;
    const newData = { ...formData, product: selectedProduct };

    setDosageOptions(selectedOption.value);
    setMedicineValue(`${navn} ${styrke}`);
    setDosageValue(varenummer);
    setFormData(newData);
    onChange(newData);
    clearResults();
  };

  const handleTypeChange = (value: any) => {
    const noPn = value.indexOf(MedicineType.PN) < 0;
    const updatedData = {
      ...formData,
      maxDailyDose: noPn ? '' : formData.maxDailyDose,
      medicineType: value,
    };

    setFormData(updatedData);
    onChange(updatedData);
  };

  const handleDosageChange = (value: string) => {
    const selectedProduct = dosageOptions.find(item => item.varenummer === value);

    setFormData({ ...formData, product: selectedProduct });
    setDosageValue(value);
  };

  const isOptionDisabled = (optionValue: MedicineType) => {
    if (optionValue === MedicineType.Increasing && (isDecreasing || isPn || isSchema)) return true;
    if (optionValue === MedicineType.Decreasing && (isIncreasing || isPn || isSchema)) return true;
    if (optionValue === MedicineType.PN && (isIncreasing || isDecreasing || isSchema)) return true;
    if (optionValue === MedicineType.BySchema && (isIncreasing || isDecreasing || isPn)) return true;

    return false;
  };

  const renderMedicineOptions = () => {
    if (!results) return null;

    return results.map((item: StringProductListKeyValuePair) => {
      const { value } = item;
      const { navn, varenummer, styrke } = value[0];

      return (
        <AcOption key={varenummer} value={navn}>{navn} {styrke}</AcOption>
      );
    });
  };

  const renderTypesOptions = () => {
    return translateEnumOptions(MedicineType).map((option, index) => {
      const { label: optionLabel, value: optionValue } = option;
      const disableOption = isOptionDisabled(optionValue as MedicineType);

      return (
        <Option key={index} value={optionValue} disabled={disableOption}>{optionLabel}</Option>
      );
    });
  };

  return (
    <div className="common-form">
      <div className="common-form__row">
        <div className="common-form__col">
          <Autocomplete
            title="Medicin"
            value={medicineValue}
            data={results}
            isLoading={isLoading}
            onSelect={handleMedicineSelect}
            onSearch={searchMedicine}
            emptyText={notFoundMedicineStatus}
            onChange={setMedicineValue}
            clearValueAfterSelect
          >
            {renderMedicineOptions()}
          </Autocomplete>
          <SelectMenu
            title="Dispenseringsform"
            name="dosageValue"
            value={dosageValue}
            valueKey="varenummer"
            labelKey="pakning"
            options={dosageOptions}
            onChange={handleDosageChange}
          />
          <DatePicker
            title="Administrativ startdato"
            name="fromDate"
            maxDate={disableEndDate ? undefined : toDate}
            value={fromDate}
            onChange={handleFieldChange}
          />
          <DatePicker
            title="Administrativ slutdato"
            name="toDate"
            value={toDate}
            disabled={disableEndDate}
            minDate={moment()}
            onChange={handleFieldChange}
          />
          <SelectMenu
            title="Type"
            name="medicineType"
            value={medicineType}
            valueKey="value"
            labelKey="label"
            onChange={handleTypeChange}
          >
            {renderTypesOptions()}
          </SelectMenu>
          <TextInput
            title={`${incDecPrefix}trapning efter X administreringer`}
            name="incDecEveryX"
            type="number"
            disabled={disableIncDecFields}
            value={incDecEveryX}
            onChange={handleFieldChange}
          />
        </div>
        <div className="common-form__col">
          <SelectMenu
            title="Doseringsenhed"
            name="doseUnit"
            value={doseUnit}
            valueKey="value"
            labelKey="label"
            options={doseUnitOptions}
            onChange={handleFieldChange}
          />
          <TextInput
            title="Daglig dosis"
            name="dailyDose"
            type="number"
            value={dailyDose}
            error={doseError}
            onChange={handleFieldChange}
          />
          <TextInput
            title="Maksimal daglig dosis"
            name="maxDailyDose"
            type="number"
            disabled={!isPn}
            value={maxDailyDose}
            error={doseError}
            onChange={handleFieldChange}
          />
          <TextInput
            title="Startdosis"
            name="incDecStartDose"
            type="number"
            disabled={disableIncDecFields}
            value={incDecStartDose}
            error={incDoseError || decDoseError}
            onChange={handleFieldChange}
          />
          <TextInput
            title="Slutdosis"
            name="incDecEndDose"
            type="number"
            disabled={disableIncDecFields}
            value={incDecEndDose}
            error={incDoseError || decDoseError}
            onChange={handleFieldChange}
          />
          <TextInput
            title={`${incDecPrefix}trapningsdosis`}
            name="incDecDose"
            type="number"
            disabled={disableIncDecFields}
            value={incDecDose}
            onChange={handleFieldChange}
          />
        </div>
      </div>
    </div>
  );
};

