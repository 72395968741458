import prodocApi, {
  Center,
  CitizenEnrollment,
  Department,
  Employee,
  EmployeeEnrollment,
  EmployeeEnrollmentResult,
  Position,
} from '../services/prodocApi';
import { actionCreator, isType } from './Action';
import { showErrorMessage, showSuccessMessage } from './notificationStore';
import { goBack, navigateTo, navigateToEmployeeEnroll, navigateToHome } from './navigationStore';
import { LeaderCreate, LeaderSearch } from '../types/commonItems';
import { employeeDefault } from '../services/defaults';
import { PAGES_ITEMS, setPageItemSaved } from './pagesStore';
import { EditEmployeeEntity, EmployeeDischarge, EmployeeOverview } from '../types/employee';
import { UtilsNavigation } from '../utils/UtilsNavigation';
import { ApplicationState } from './index';
import { UtilsData } from '../utils/UtilsData';
import { setOverviewData } from './overviewStore';
import { setDepartmentLeader, setUserDepartments } from './departmentStore';
import { CENTER_LEADER_FIELD_NAMES } from '../constants/center';
import { ROUTES } from '../constants/routes';
import { URLS } from '../constants/urls';
import { UtilsOverview } from '../utils/UtilsOverview';
import { setCenterLeaderById } from './centerStore';
import { MESSAGES } from '../constants/messages';

export interface EmployeeState {
  employeeSearchResult: Employee[];
  editEmployee: EditEmployeeEntity;
  leaderCreate: LeaderCreate;
  leaderSearchResult: LeaderSearch;
  employeeOverview: EmployeeOverview;
  dischargeEmployeesEnrollments: EmployeeEnrollment[];
  enrollmentDepartments: string[];
  availableSearchEmployees: EmployeeEnrollmentResult[];
  userEmployeesEnrollments: EmployeeEnrollment[];
}

const defaultState: EmployeeState = {
  employeeSearchResult: null,
  editEmployee: {},
  leaderCreate: null,
  leaderSearchResult: {
    employeeEnrollmentAdministrativeId: [],
    employeeEnrollment2LeaderId: [],
    employeeEnrollmentLeaderId: [],
  },
  employeeOverview: {
    enrollments: [],
    enrollmentDepartments: [],
  },
  dischargeEmployeesEnrollments: [],
  enrollmentDepartments: [],
  availableSearchEmployees: [],
  userEmployeesEnrollments: [],
};

export const setEmployeeSearchResult = actionCreator<Employee[]>('SET_EMPLOYEE_SEARCH_RESULT');
export const setEmployeeEdit = actionCreator<EditEmployeeEntity>('SET_EMPLOYEE_EDIT');
export const setLeaderCreateData = actionCreator<LeaderCreate>('SET_LEADER_CREATE_DATA');
export const setLeaderSearchResult = actionCreator<LeaderSearch>('SET_LEADER_SEARCH_RESULT');
export const setEmployeeOverview = actionCreator<EmployeeOverview>('SET_EMPLOYEE_OVERVIEW');
export const setEmployeeEnrollmentsForDischarge = actionCreator<EmployeeEnrollment[]>(
  'SET_EMPLOYEE_ENROLLMENTS_FOR_DISCHARGE'
);
export const setEmployeeEnrollmentDepartments = actionCreator<string[]>('SET_CITIZEN_ENROLLMENT_DEPARTMENTS');
export const setAvailableSearchEmployees = actionCreator<EmployeeEnrollmentResult[]>('SET_AVAILABLE_SEARCH_EMPLOYEES');
export const setUserEmployeeEnrollments = actionCreator<EmployeeEnrollmentResult[]>('SET_USER_EMPLOYEE_ENROLLMENTS');

export default (state = defaultState, action: any) => {
  if (isType(action, setEmployeeSearchResult)) {
    return {
      ...state,
      employeeSearchResult: action.payload,
    };
  }

  if (isType(action, setEmployeeEdit)) {
    return {
      ...state,
      editEmployee: action.payload,
    };
  }

  if (isType(action, setLeaderCreateData)) {
    return {
      ...state,
      leaderCreate: action.payload,
    };
  }

  if (isType(action, setLeaderSearchResult)) {
    return {
      ...state,
      leaderSearchResult: action.payload,
    };
  }

  if (isType(action, setEmployeeOverview)) {
    return {
      ...state,
      employeeOverview: action.payload,
    };
  }

  if (isType(action, setEmployeeEnrollmentsForDischarge)) {
    return {
      ...state,
      dischargeEmployeesEnrollments: action.payload,
    };
  }

  if (isType(action, setEmployeeEnrollmentDepartments)) {
    return {
      ...state,
      enrollmentDepartments: action.payload,
    };
  }

  if (isType(action, setAvailableSearchEmployees)) {
    return {
      ...state,
      availableSearchEmployees: action.payload,
    };
  }

  return state;
};

export const getEmployeeById = (employeeId: string) => {
  return async dispatch => {
    try {
      const getEmployeeResponse: Employee = await prodocApi.employee.getById(employeeId);
      const employeeEnrollments = await prodocApi.employeeEnrollment.getByEmployee(employeeId, true);

      dispatch(setEmployeeEdit({ data: getEmployeeResponse, enrollments: employeeEnrollments }));
    } catch (e) {
      dispatch(showErrorMessage({ message: e.message }));
    }
  };
};

export const addEmployee = (data: Employee) => {
  return async (dispatch, getState) => {
    const state = getState();
    const employeeState = state.employees;
    const leaderCreate: LeaderCreate = employeeState.leaderCreate;
    const leaderSearch = employeeState.leaderSearchResult;

    const getItemRoute = (leaderCreate: LeaderCreate) => {
      const { department, center } = leaderCreate;

      if (department) {
        return department.id
          ? UtilsNavigation.buildRoute(URLS.DEPARTMENT, URLS.DETAILS, department.id, department.centerId)
          : UtilsNavigation.buildRoute(URLS.DEPARTMENT, URLS.ADD, department.centerId);
      }

      return center.id ? UtilsNavigation.buildRoute(URLS.DEPARTMENT, URLS.DETAILS, center.id) : ROUTES.CENTER_ADD;
    };

    try {
      dispatch(setPageItemSaved(PAGES_ITEMS.EMPLOYEE_FORM, false));

      const employee = await prodocApi.employee.add(data);

      if (leaderCreate) {
        const { leaderFieldName } = leaderCreate;
        const nextRoute = getItemRoute(leaderCreate);
        const isSecondaryLeader = leaderFieldName === CENTER_LEADER_FIELD_NAMES.SECONDARY_LEADER;
        const updatedSearchResult = isSecondaryLeader ? [...leaderSearch[leaderFieldName], employee] : [employee];

        dispatch(setLeaderCreateData({ ...leaderCreate, leaderId: employee.id }));
        dispatch(setLeaderSearchResult({ ...leaderSearch, [leaderFieldName]: updatedSearchResult }));
        dispatch(showSuccessMessage({ message: 'Medarbejder oprettet' }));
        dispatch(navigateTo(nextRoute));
      } else {
        const resetEmployeeData = { ...employeeDefault(), id: null };

        dispatch(setEmployeeEdit({ data: resetEmployeeData, enrollments: [] }));
        dispatch(showSuccessMessage({ message: 'Medarbejder oprettet' }));
        dispatch(setPageItemSaved(PAGES_ITEMS.EMPLOYEE_FORM, true));
        dispatch(navigateToEmployeeEnroll(employee.id));
      }
    } catch (e) {
      if (!e.response) return;

      dispatch(showErrorMessage({ message: 'Brugernavn eksisterer allerede' }));
    }
  };
};

export const updateEmployee = (data: Employee) => {
  return async (dispatch, getState) => {
    try {
      const state: ApplicationState = getState();

      await prodocApi.employee.update(data);

      dispatch(showSuccessMessage({ message: 'Medarbejder oplysninger opdateret' }));

      if (UtilsNavigation.shouldReturnToOverview()) {
        const updatedOverviewData = UtilsOverview.updateCompanyOverviewItemData(state.overview.data, data);

        dispatch(setOverviewData(updatedOverviewData));
        dispatch(goBack());
      }
    } catch (e) {
      if (!e.response) return;

      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    }
  };
};

export const searchEmployee = (searchStr: string) => {
  return async dispatch => {
    try {
      const employeeSearchResult: Employee[] = await prodocApi.employee.search(searchStr);

      dispatch(setEmployeeSearchResult(employeeSearchResult));
    } catch (e) {}
  };
};

export const searchLeader = (searchStr: string, name: string) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const searchResult = state.employees.leaderSearchResult;

      const leaderSearchResult: Employee[] = await prodocApi.employeeEnrollment.search(searchStr);
      const foundItems = searchResult[name] || [];

      const filteredResults = leaderSearchResult.filter(item => {
        return !foundItems.find(existedItem => existedItem.id === item.id);
      });

      if (filteredResults.length) {
        const newLeaderSearchData: LeaderSearch = {
          ...searchResult,
          [name]: [...foundItems, ...leaderSearchResult],
        };

        dispatch(setLeaderSearchResult(newLeaderSearchData));
      }
    } catch (e) {}
  };
};

export const loadLeaders = (ids: string[], item: Center | Department) => {
  return async dispatch => {
    try {
      const filteredIds = ids.filter(id => !!id);
      const leadersResponse: Employee[] = await prodocApi.employee.getMany(filteredIds);

      const { employeeEnrollmentLeaderId, employeeEnrollment2LeaderId, employeeEnrollmentAdministrativeId } =
        item as Center;
      const leaderObj =
        employeeEnrollmentLeaderId && leadersResponse.find(item => employeeEnrollmentLeaderId === item.id);
      const secondaryLeaders =
        employeeEnrollment2LeaderId?.length &&
        leadersResponse.filter(item => !!employeeEnrollment2LeaderId.find(leaderId => leaderId === item.id));
      const administrativeLeaders =
        employeeEnrollmentAdministrativeId?.length &&
        leadersResponse.filter(item => !!employeeEnrollmentAdministrativeId.find(leaderId => leaderId === item.id));
      const mainLeader = leaderObj ? [leaderObj] : [];

      const updatedLeadersData: LeaderSearch = {
        employeeEnrollmentLeaderId: mainLeader,
        employeeEnrollment2LeaderId: secondaryLeaders,
        employeeEnrollmentAdministrativeId: administrativeLeaders,
      };

      dispatch(setLeaderSearchResult(updatedLeadersData));
    } catch (e) {}
  };
};

export const enrollEmployee = (data: EmployeeEnrollment, departmentIds: string[]) => {
  return async dispatch => {
    try {
      const employeesEnrollments = await prodocApi.employeeEnrollment.getManyByDepartments({
        departmentIds,
      });
      const enrolledDepartmentsIds = [];
      const newEnrollments = [];
      const isDepartmentLeader = data.position === Position.HeadOfDepartment;
      const isCenterLeader = data.position === Position.OrganizationalseniorLeader;
      const departments = isDepartmentLeader || isCenterLeader ? await prodocApi.department.getMany(departmentIds) : [];

      for (const departmentId of departmentIds) {
        const enrollData: EmployeeEnrollment = { ...data, departmentId };
        const existingEnrollment = employeesEnrollments.find(item => {
          return item.departmentId === departmentId && data.employeeId === item.employeeId;
        });

        if (existingEnrollment) {
          enrolledDepartmentsIds.push(departmentId);
        } else {
          newEnrollments.push(enrollData);
        }
      }

      // TODO: Refactor if case
      if (!enrolledDepartmentsIds.length) {
        for (const enrollment of newEnrollments) {
          const data = { ...enrollment };

          delete data.dischargedDate;
          const savedEnrollment = await prodocApi.employeeEnrollment.add(data);

          if (isDepartmentLeader) {
            const departmentData = departments.find(item => item.id === savedEnrollment.departmentId);

            dispatch(setDepartmentLeader(departmentData, savedEnrollment.id));
          }

          if (isCenterLeader) {
            const departmentData = departments.find(item => item.id === savedEnrollment.departmentId);

            dispatch(setCenterLeaderById(departmentData.centerId, savedEnrollment.id));
          }
        }

        dispatch(showSuccessMessage({ message: 'Medarbejder oprettet på afdeling' }));
        dispatch(navigateToHome());
      } else {
        dispatch(setEmployeeEnrollmentDepartments(enrolledDepartmentsIds));
        dispatch(showErrorMessage({ message: 'Medarbejderen er allerede indskrevet på denne afdeling' }));
      }
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    }
  };
};

export const getEmployeeOverviewData = (id: string) => {
  return async dispatch => {
    try {
      const enrollments = await prodocApi.employeeEnrollment.getByEmployee(id, true);

      const enrollmentDepartments = await Promise.all(
        enrollments.map(async item => {
          return await prodocApi.department.get(item.departmentId);
        })
      );

      dispatch(
        setEmployeeOverview({
          enrollments,
          enrollmentDepartments,
        })
      );
    } catch (e) {}
  };
};

export const getDischargeEmployeesEnrollmentsByDepartment = departmentId => {
  return async dispatch => {
    try {
      const departmentEmployees = await prodocApi.employeeEnrollment.getManyByDepartments({
        departmentIds: [departmentId],
      });

      dispatch(setEmployeeEnrollmentsForDischarge(departmentEmployees));
    } catch (e) {}
  };
};

export const dischargeEmployee = (data: EmployeeDischarge) => {
  return async dispatch => {
    try {
      const { employeeId, date } = data;

      await prodocApi.employeeEnrollment.discharge(employeeId, date);

      dispatch(navigateToHome());
      dispatch(showSuccessMessage({ message: 'Medarbejder afsluttet' }));
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    }
  };
};

export const loadEmployeeEnrollDepartments = (employeeId: string) => {
  return async dispatch => {
    try {
      const userCenters = await prodocApi.center.getAllCreatedBy();
      const centersIds = userCenters.map(center => center.id);
      const userDepartments = await prodocApi.department.getManyFromCenter(centersIds);

      const employeeEnrollments: CitizenEnrollment[] =
        (await prodocApi.employeeEnrollment.getByEmployee(employeeId, true)) || [];
      const filteredDepartments = userDepartments.filter(department => {
        return employeeEnrollments.find(enrollment => enrollment.departmentId === department.id);
      });

      dispatch(setUserDepartments(filteredDepartments));
    } catch (e) {}
  };
};

export const loadAvailableSearchEmployees = () => {
  return async (dispatch, getState) => {
    try {
      const state: ApplicationState = getState();
      const { department } = state;
      const { userDepartments } = department;
      const departmentIds = userDepartments.map(item => item.id);
      const employees = await prodocApi.employeeEnrollment.getManyByDepartments({ departmentIds });
      const filteredEmployees = UtilsData.removeDuplicatesFromArray(employees, 'id');

      dispatch(setAvailableSearchEmployees(filteredEmployees));
    } catch (e) {}
  };
};
