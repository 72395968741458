import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Goal, GoalStatus } from '../../../services/prodocApi';
import Icon, { ICONS } from '../../_shared/Icon/Icon';
import Tag from '../../_shared/Tag/Tag';
import { DATE_FORMAT } from '../../../constants/common';
import Button from '../../_shared/_controls/Button/Button';
import { Tooltip } from '../../_shared/Tooltip/Tooltip';
import { UtilsData } from '../../../utils/UtilsData';

import './GoalCard.scss';

interface Props {
  data: Goal;
  isSubItem?: boolean;
  onEdit(data: Goal): void;
  onStatusClick?(id: string): void;
  onNoteClick(id: string): void;
}

const STATUS_ICON_DATA_BY_STATUS = {
  [GoalStatus.Achieved]: {
    className: 'goal-card__status-border--achieved',
  },
  [GoalStatus.NotAchieved]: {
    className: 'goal-card__status-border--notachieved',
  },
  [GoalStatus.Paused]: {
    className: 'goal-card__status-border--paused',
  },
};

export const GoalCard: React.FC<Props> = props => {
  const { data, onEdit, isSubItem, onStatusClick, onNoteClick } = props;
  const { fromDate, id, toDate, goalStatus, goalMethodTheme, title, description, goalMethod } = data;
  const { t } = useTranslation();
  const wrapClassname = classNames('goal-card', { 'goal-card--sub': isSubItem });
  const isGoalClosed = goalStatus === GoalStatus.Achieved || goalStatus === GoalStatus.NotAchieved;
  const handledDescription = !UtilsData.isDefaultHtmlString(description) ? (
    <span dangerouslySetInnerHTML={{ __html: description }} />
  ) : (
    ''
  );

  const handleEdit = () => {
    onEdit(data);
  };

  const handleStatusCLick = () => {
    onStatusClick && onStatusClick(id);
  };

  const handleNoteCLick = () => {
    onNoteClick(id);
  };

  return (
    <div className={wrapClassname}>
      <Tag
        style={{ cursor: 'pointer' }}
        className={STATUS_ICON_DATA_BY_STATUS[goalStatus]?.className}
        onClick={handleEdit}>
        <div className="goal-card__head">
          <div className="goal-card__title">
            <Tooltip text={handledDescription}>
              <div className="goal-card__name">{title}</div>
            </Tooltip>
            {!isSubItem && (
              <Button
                title="Målstatus"
                icon={ICONS.plus}
                disabled={isGoalClosed}
                className="goal-card__status-btn"
                onClick={handleStatusCLick}
              />
            )}
            {/* <button className="goal-card__edit-btn" type="button" onClick={handleEdit}>
              <Icon name={ICONS.edit} />
            </button> */}
          </div>
          <div className="goal-card__head-action">
            {/* {!isGoalClosed && (
              <Button icon={ICONS.read} className="goal-card__note-btn empty-btn" onClick={handleNoteCLick} />
            )} */}
            {/* <Tooltip text={t(goalStatus)}>
              <Icon className={STATUS_ICON_DATA_BY_STATUS[goalStatus].className} />
            </Tooltip> */}
          </div>
        </div>
        <div className="goal-card__body">
          <div>
            {!isSubItem && <div className="goal-card__method">{t(goalMethod)}</div>}
            {!isSubItem && <div className="goal-card__method">{t(goalMethodTheme)}</div>}
            <div className="goal-card__date">Start dato {moment(fromDate).format(DATE_FORMAT)}</div>
            <div className="goal-card__date">Slut dato {moment(toDate).format(DATE_FORMAT)}</div>
          </div>
          {/* {!isSubItem && (
            <Button
              title="Målstatus"
              icon={ICONS.plus}
              disabled={isGoalClosed}
              className="goal-card__status-btn"
              onClick={handleStatusCLick}
            />
          )} */}
        </div>
      </Tag>
    </div>
  );
};
