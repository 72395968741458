import { CalendarItem, CalendarItemType, CalendarStatus, Center, Citizen, TypeOfConviction, CitizenEnrollment, EnrollFromType, DischargeCause, CitizenEnrollmentResult, Department, DepartmentCitizenEnrollmentResult, DepartmentDto, DepartmentResult, Employee, AuthType, EmployeeEnrollment, Position, EmployeeEnrollmentResult, ExternalContact, ExternalContactCategory, ExternalContactDto, ExternalContactSubCategory, Favorite, FavoriteType, FileShare, UploadType, DocumentType, FileShareDto, ForumComment, ForumMessage, ForumMessageResult, Goal, GoalType, GoalStatus, GoalMethod, GoalMethodTheme, GoalResult, GoalScorer, HealthCare, HealthCareType, HealthCareProblem, HealthCareCitizenAct, HealthCareDto, HttpContent, HttpMethod, Measurement, MeasurementType, RiskAssessment, MeasurementDto, Medicine, DoseUnit, MedicineType, MedicineState, MedicinePassout, Note, NoteDto, Posting, Product, ProductDetails, ResourceCalendarItem, ResourceCalendarItemType, ResourceCalendarPage, Risk, RiskLevel, StringProductListKeyValuePair, StringStringIEnumerableKeyValuePair, Tag, Token, UseOfForceCalculations, UseOfForceDto, UseOfForceStatus, Version } from "./prodocApi"

export const calendarItemDefault = (): CalendarItem => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		calendarItemType: CalendarItemType.Normal,
		fromDate: new Date('1970-01-01'),
		toDate: new Date('1970-01-01'),
		title: '',
		citizenId: '',
		citizenEnrollmentId: '',
		done: false,
		allday: false,
		note: '',
		groupId: '',
		calendarStatus: CalendarStatus.Unanswered,
		isAccepted: false,
		isPrivate: false,
		isEditable: false,
		isRepetition: false,
		departmentId: '',
		employeeId: '',
		employeeEnrollmentId: '',
		goalIds: [],
		privateAppointment: false,
		healthAppointment: false,
		health: [],
		entityId: '',
		entityObjectType: '',
		pnEvaluated: false,
	} as CalendarItem
}

export const centerDefault = (): Center => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		name: '',
		zipCode: '',
		city: '',
		address: '',
		phoneNumber: '',
		email: '',
		employeeEnrollmentLeaderId: '',
		employeeEnrollment2LeaderId: [],
		employeeEnrollmentAdministrativeId: [],
	} as Center
}

export const citizenDefault = (): Citizen => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		cpr: '',
		firstname: '',
		lastname: '',
		phone: '',
		address: '',
		alternativeAddress: '',
		zipCode: '',
		city: '',
		foreignId: '',
		cave: '',
		diagnoses: '',
		vaccines: '',
		typeOfConviction: TypeOfConviction.Domstype1,
		riskAssessmentGreen: '',
		riskAssessmentYellow: '',
		riskAssessmentRed: '',
		actMunicipallity: '',
		payMunicipallity: '',
		actSocialworker: '',
		paySocialworker: '',
		convictionStartDate: new Date('1970-01-01'),
		convictionEndtDate: new Date('1970-01-01'),
		name: '',
	} as Citizen
}

export const citizenEnrollmentDefault = (): CitizenEnrollment => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		departmentId: '',
		citizenId: '',
		enrollmentDate: new Date('1970-01-01'),
		dischargedDate: new Date('1970-01-01'),
		enrolledFrom: EnrollFromType.PrivateLiving,
		dischargeCause: DischargeCause.None,
		color: '',
		riskAssessmentGreen: '',
		riskAssessmentGreenAct: '',
		riskAssessmentYellow: '',
		riskAssessmentYellowAct: '',
		riskAssessmentRed: '',
		riskAssessmentRedAct: '',
	} as CitizenEnrollment
}

export const citizenEnrollmentResultDefault = (): CitizenEnrollmentResult => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		departmentId: '',
		citizenId: '',
		enrollmentDate: new Date('1970-01-01'),
		dischargedDate: new Date('1970-01-01'),
		enrolledFrom: EnrollFromType.PrivateLiving,
		actMunicipallity: '',
		payMunicipallity: '',
		socialworker: '',
		dischargeCause: DischargeCause.None,
		color: '',
		firstname: '',
		lastname: '',
		cpr: '',
		departmentName: '',
		centerName: '',
	} as CitizenEnrollmentResult
}

export const departmentDefault = (): Department => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		centerId: '',
		name: '',
		zipCode: '',
		city: '',
		address: '',
		phoneNumber: '',
		email: '',
		employeeEnrollmentLeaderId: '',
		employeeEnrollment2LeaderId: [],
		employeeEnrollmentAdministrativeId: [],
	} as Department
}

export const departmentCitizenEnrollmentResultDefault = (): DepartmentCitizenEnrollmentResult => {
	return {
		departments: [],
		citizenEnrollments: [],
	} as DepartmentCitizenEnrollmentResult
}

export const departmentDtoDefault = (): DepartmentDto => {
	return {
		departmentIds: [],
	} as DepartmentDto
}

export const departmentResultDefault = (): DepartmentResult => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		centerId: '',
		name: '',
		zipCode: '',
		city: '',
		address: '',
		phoneNumber: '',
		email: '',
		employeeEnrollmentLeaderId: '',
		employeeEnrollment2LeaderId: [],
		employeeEnrollmentAdministrativeId: [],
		centerName: '',
	} as DepartmentResult
}

export const employeeDefault = (): Employee => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		cpr: '',
		firstname: '',
		lastname: '',
		address: '',
		zipCode: '',
		city: '',
		phone: '',
		phonePrivate: '',
		email: '',
		emailPrivate: '',
		username: '',
		password: '',
		authType: AuthType.Employee,
		fullName: '',
	} as Employee
}

export const employeeEnrollmentDefault = (): EmployeeEnrollment => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		departmentId: '',
		employeeId: '',
		enrollmentDate: new Date('1970-01-01'),
		position: Position.Administration,
		dischargedDate: new Date('1970-01-01'),
		color: '',
	} as EmployeeEnrollment
}

export const employeeEnrollmentResultDefault = (): EmployeeEnrollmentResult => {
	return {
		id: '',
		departmentId: '',
		employeeId: '',
		enrollmentDate: new Date('1970-01-01'),
		position: Position.Administration,
		dischargedDate: new Date('1970-01-01'),
		color: '',
		firstname: '',
		lastname: '',
		departmentName: '',
		email: '',
		createdOn: new Date('1970-01-01'),
	} as EmployeeEnrollmentResult
}

export const externalContactDefault = (): ExternalContact => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		name: '',
		phone: '',
		email: '',
		subCategoryId: '',
		address: '',
		zipCode: '',
		categoryId: '',
	} as ExternalContact
}

export const externalContactCategoryDefault = (): ExternalContactCategory => {
	return {
		id: '',
		name: '',
		title: '',
		subCategories: [],
	} as ExternalContactCategory
}

export const externalContactDtoDefault = (): ExternalContactDto => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		name: '',
		phone: '',
		email: '',
		citizenId: '',
		subCategoryId: '',
		address: '',
		zipCode: '',
		categoryId: '',
		categoryName: '',
		subCategoryName: '',
	} as ExternalContactDto
}

export const externalContactSubCategoryDefault = (): ExternalContactSubCategory => {
	return {
		id: '',
		name: '',
		title: '',
	} as ExternalContactSubCategory
}

export const favoriteDefault = (): Favorite => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		entityId: '',
		employeeEnrollmentId: '',
		favoriteType: FavoriteType.Citizen,
	} as Favorite
}

export const fileShareDefault = (): FileShare => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		blobFileName: '',
		fileName: '',
		citizenEnrollmentId: '',
		departmentId: '',
		uploadType: UploadType.Citizen,
		documentType: DocumentType.Document,
		description: '',
		tagIds: [],
		readByEmployeeIds: [],
	} as FileShare
}

export const fileShareDtoDefault = (): FileShareDto => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		blobFileName: '',
		fileName: '',
		citizenEnrollmentId: '',
		departmentId: '',
		uploadType: UploadType.Citizen,
		documentType: DocumentType.Document,
		description: '',
		tagIds: [],
		readByEmployeeIds: [],
		tags: [],
		createdByName: '',
	} as FileShareDto
}

export const forumCommentDefault = (): ForumComment => {
	return {
		comment: '',
		createdBy: '',
		createdByName: '',
		createdOn: new Date('1970-01-01'),
		deleted: false,
	} as ForumComment
}

export const forumMessageDefault = (): ForumMessage => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		header: '',
		text: '',
		comments: [],
		pinned: false,
	} as ForumMessage
}

export const forumMessageResultDefault = (): ForumMessageResult => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		header: '',
		text: '',
		comments: [],
		pinned: false,
		createdByName: '',
	} as ForumMessageResult
}

export const goalDefault = (): Goal => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		title: '',
		description: '',
		citizenEnrollmentId: '',
		goalType: GoalType.DevelopFunctionalAbility,
		goalStatus: GoalStatus.OnGoing,
		goalMethod: GoalMethod.VUM2,
		goalMethodTheme: GoalMethodTheme.VUM2_SelfCare,
		fromDate: new Date('1970-01-01'),
		evaluationDate: new Date('1970-01-01'),
		evaluationText: '',
		toDate: new Date('1970-01-01'),
		parentGoalId: '',
		employeeEnrollmentIds: [],
		score: 0,
	} as Goal
}

export const goalResultDefault = (): GoalResult => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		title: '',
		description: '',
		citizenEnrollmentId: '',
		goalType: GoalType.DevelopFunctionalAbility,
		goalStatus: GoalStatus.OnGoing,
		goalMethod: GoalMethod.VUM2,
		goalMethodTheme: GoalMethodTheme.VUM2_SelfCare,
		fromDate: new Date('1970-01-01'),
		evaluationDate: new Date('1970-01-01'),
		evaluationText: '',
		toDate: new Date('1970-01-01'),
		parentGoalId: '',
		employeeEnrollmentIds: [],
		score: 0,
		subGoals: [],
		goalScorer: [],
	} as GoalResult
}

export const goalScorerDefault = (): GoalScorer => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		score: 0,
		goalId: '',
		description: '',
	} as GoalScorer
}

export const healthCareDefault = (): HealthCare => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		care: HealthCareType.Communication_ProblemsWithCommunication,
		problem: HealthCareProblem.Currently,
		citizenAct: HealthCareCitizenAct.Undecided,
		description: '',
		action: '',
		partners: '',
		citizenEnrollmentId: '',
		isActive: false,
		medicineIds: [],
		blobFileNames: [],
	} as HealthCare
}

export const healthCareDtoDefault = (): HealthCareDto => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		care: HealthCareType.Communication_ProblemsWithCommunication,
		problem: HealthCareProblem.Currently,
		citizenAct: HealthCareCitizenAct.Undecided,
		description: '',
		action: '',
		partners: '',
		citizenEnrollmentId: '',
		isActive: false,
		medicineIds: [],
		blobFileNames: [],
		citizenName: '',
		createdByName: '',
	} as HealthCareDto
}

export const httpContentDefault = (): HttpContent => {
	return {
		headers: [],
	} as HttpContent
}

export const httpMethodDefault = (): HttpMethod => {
	return {
		method: '',
	} as HttpMethod
}

export const measurementDefault = (): Measurement => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		citizenEnrollmentId: '',
		citizenId: '',
		remarks: '',
		measurementType: MeasurementType.Bloodsugar,
		fromDate: new Date('1970-01-01'),
		toDate: new Date('1970-01-01'),
		date: new Date('1970-01-01'),
		diastolisk: 0,
		systolisk: 0,
		puls: 0,
		millimol: 0,
		height: 0,
		weight: 0,
		confusion: 0,
		irritability: 0,
		loudBehavior: 0,
		verbalThreats: 0,
		physicalThreats: 0,
		attack: 0,
		m1: 0,
		bodyTemperature: 0,
		riskAssessment: RiskAssessment.Green,
		description: '',
		whatHappendText: '',
		whatDidYouDoText: '',
	} as Measurement
}

export const measurementDtoDefault = (): MeasurementDto => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		citizenEnrollmentId: '',
		citizenId: '',
		remarks: '',
		measurementType: MeasurementType.Bloodsugar,
		fromDate: new Date('1970-01-01'),
		toDate: new Date('1970-01-01'),
		date: new Date('1970-01-01'),
		diastolisk: 0,
		systolisk: 0,
		puls: 0,
		millimol: 0,
		height: 0,
		weight: 0,
		confusion: 0,
		irritability: 0,
		loudBehavior: 0,
		verbalThreats: 0,
		physicalThreats: 0,
		attack: 0,
		m1: 0,
		bodyTemperature: 0,
		riskAssessment: RiskAssessment.Green,
		description: '',
		whatHappendText: '',
		whatDidYouDoText: '',
		citizens: [],
		createdByName: '',
	} as MeasurementDto
}

export const medicineDefault = (): Medicine => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		fromDate: new Date('1970-01-01'),
		toDate: new Date('1970-01-01'),
		doseUnit: DoseUnit.Saet,
		cave: '',
		citizenId: '',
		citizenEnrollmentId: '',
		medicineType: MedicineType.DoseDispensing,
		schedules: '',
		dailyDose: '',
		maxDailyDose: '',
		actualDose: '',
		medicineStates: [],
		incDecStartDose: '',
		incDecEndDose: '',
		incDecEveryX: '',
		incDecDose: '',
		noCalendarItem: false,
		note: '',
	} as Medicine
}

export const medicineStateDefault = (): MedicineState => {
	return {
		medicinePassout: MedicinePassout.Awaits,
		passoutFailedExplanation: '',
		doze: '',
		timeStamp: new Date('1970-01-01'),
	} as MedicineState
}

export const noteDefault = (): Note => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		citizenEnrollmentId: '',
		citizenId: '',
		departmentId: '',
		text: '',
		health: [],
		fromDate: new Date('1970-01-01'),
		toDate: new Date('1970-01-01'),
		subGoalId: '',
	} as Note
}

export const noteDtoDefault = (): NoteDto => {
	return {
		citizenEnrollmentId: '',
		text: '',
		health: [],
		fromDate: new Date('1970-01-01'),
		toDate: new Date('1970-01-01'),
		citizenName: '',
		departmentName: '',
		createdByName: '',
		modifiedByName: '',
		id: '',
		createdOn: new Date('1970-01-01'),
		modifiedOn: new Date('1970-01-01'),
		subGoalId: '',
		createdBy: '',
		citizens: [],
		departments: [],
	} as NoteDto
}

export const postingDefault = (): Posting => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		citizenEnrollmentId: '',
		amount: 0,
		transactionDate: new Date('1970-01-01'),
		description: '',
		appendixes: [],
		date: new Date('1970-01-01'),
	} as Posting
}

export const productDefault = (): Product => {
	return {
		navn: '',
		varenummer: '',
		firma: '',
		styrke: '',
		detaljer: '',
		pakning: '',
	} as Product
}

export const productDetailsDefault = (): ProductDetails => {
	return {
		navn: '',
		varenummer: '',
		styrke: '',
		pakning: '',
		virksomtStof: '',
		firma: '',
		atcKode: '',
		dosisdispensering: false,
		udleveringsgruppe: '',
		prisPrPakning: 0,
		prisPrEnhed: 0,
		aip: 0,
		tilskudBeregnesAf: 0,
		udgaaet: false,
		udgaaetDato: new Date('1970-01-01'),
		substitutioner: [],
		billigereKombinationer: [],
		dosering: '',
		indikation: '',
		trafikAdvarsel: false,
		ddd: 0,
		opbevaringsbetingelser: '',
		nbsSpeciale: '',
		haandkoeb: false,
		tilskudKode: '',
		tilskudTekst: '',
	} as ProductDetails
}

export const resourceCalendarItemDefault = (): ResourceCalendarItem => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		resourceCalendarItemType: ResourceCalendarItemType.Administrative,
		resourceCalendarPage: ResourceCalendarPage.Template,
		fromDate: new Date('1970-01-01'),
		toDate: new Date('1970-01-01'),
		employeeId: '',
		overrideHours: '',
		fullDay: false,
		departmentId: '',
		dayNumber: 0,
	} as ResourceCalendarItem
}

export const riskDefault = (): Risk => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		riskLevel: RiskLevel.None,
		text: '',
		citizenId: '',
	} as Risk
}

export const stringProductListKeyValuePairDefault = (): StringProductListKeyValuePair => {
	return {
		key: '',
		value: [],
	} as StringProductListKeyValuePair
}

export const stringStringIEnumerableKeyValuePairDefault = (): StringStringIEnumerableKeyValuePair => {
	return {
		key: '',
		value: [],
	} as StringStringIEnumerableKeyValuePair
}

export const tagDefault = (): Tag => {
	return {
		id: '',
		modifiedOn: new Date('1970-01-01'),
		createdOn: new Date('1970-01-01'),
		createdBy: '',
		modifiedBy: '',
		name: '',
	} as Tag
}

export const tokenDefault = (): Token => {
	return {
		token: '',
	} as Token
}

export const useOfForceCalculationsDefault = (): UseOfForceCalculations => {
	return {
		totalYearCount: 0,
		daysSince: 0,
	} as UseOfForceCalculations
}

export const useOfForceDtoDefault = (): UseOfForceDto => {
	return {
		id: '',
		documentName: '',
		status: UseOfForceStatus.Employee,
		fromEmployeeEnrollment: '',
		ownerEmployeeEnrollment: '',
		version: '',
		createdOn: new Date('1970-01-01'),
		modifiedOn: new Date('1970-01-01'),
		createdByName: '',
		createdByDepartmentName: '',
		createdByCenterName: '',
		fromDate: new Date('1970-01-01'),
		toDate: new Date('1970-01-01'),
		jsonString: '',
		citizens: [],
	} as UseOfForceDto
}

export const versionDefault = (): Version => {
	return {
		major: 0,
		minor: 0,
		build: 0,
		revision: 0,
		majorRevision: 0,
		minorRevision: 0,
	} as Version
}

