import React, { useEffect, useRef, useState } from 'react';
import { usePageTitle, useZipCode } from '../../hooks/common-hooks';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip, Table, Card, Spin, AutoComplete, Select } from 'antd';
import { ColumnType } from 'antd/es/table';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../constants/common';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import prodocApi, { ExternalContact, ExternalContactCategory, ExternalContactDto } from '../../services/prodocApi';
import { useHistory, useParams } from 'react-router';
import TextInput from '../../components/_shared/_controls/TextInput/TextInput';
import { CardActions } from '../../components/_shared/CardActions/CardActions';
import { externalContactDefault } from '../../services/defaults';
import Button from '../../components/_shared/_controls/Button/Button';
import { ROUTES } from '../../constants/routes';
import Autocomplete from '../../components/_shared/Autocomplete/Autocomplete';
import { useDebouncedCallback } from 'use-debounce';
import { useValidation } from '../../hooks/validation-hooks';
import { externalContactCreateRules } from '../../utils/UtilsValidation';
import SelectMenu from '../../components/_shared/_controls/SelectMenu/SelectMenu';

function Auto<T>(props: {
  options: T[];
  onSearch: (value: string) => void;
  valueProp: string;
  labelProp: string;
  onChange: (value: string, label: string) => void;
  value: string;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
}) {
  let { options, onSearch, valueProp, labelProp, onChange, value, error, disabled } = props;

  return (
    <>
      <div style={{ whiteSpace: 'nowrap' }}>Navn</div>
      <Select
        value={value}
        style={{ width: '100%', marginBottom: '20px' }}
        // placeholder="try to type `b`"
        onSearch={onSearch}
        onChange={(val, obj) => onChange((obj as any).value, (obj as any).label)}
        showSearch
        options={(options || []).map(d => ({ value: d[valueProp], label: d[labelProp] }))}
        notFoundContent={null}
        filterOption={() => true}
        status={error ? 'error' : undefined}
        disabled={disabled}
      />
    </>
  );
}

export function CreateExternalContact() {
  const history = useHistory();
  const { citizenId, id } = useParams<{ citizenId: string; id: string }>();
  const [externalContact, setExternalContact] = useState<ExternalContactDto>({ ...externalContactDefault, citizenId });
  const [isLoading, setisLoading] = useState(false);
  const [externalContacts, setExternalContacts] = useState<ExternalContactDto[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [selectedExternalContact, setSelectedExternalContact] = useState({});
  const [externalContactCategories, setExternalContactCategories] = useState<ExternalContactCategory[]>([]);

  const { validate, errors } = useValidation(externalContactCreateRules);
  const isEdit = !!id;
  usePageTitle(`${isEdit ? 'Rediger' : 'Tilføj'} ekstern kontakt`);

  useEffect(() => {
    async function init() {
      const categories = await prodocApi.getList.getExternalContactCategories();
      setExternalContactCategories(categories);
      if (isEdit) {
        const result = await prodocApi.externalContact.get(id);
        setExternalContact(result);
      }
    }
    init();
  }, []);

  function handleFieldsChange(value: any, name: string) {
    setExternalContact({ ...externalContact, [name]: value });
  }

  async function handleOk() {
    if (validate(externalContact)) {
      if (isEdit) {
        await prodocApi.externalContact.update(externalContact);
      } else {
        await prodocApi.externalContact.add(externalContact);
      }
      history.push(ROUTES.EXTERNALCONTACTS.replace(':citizenId', citizenId));
    }
  }

  function handleCancel() {
    history.push(ROUTES.EXTERNALCONTACTS.replace(':citizenId', citizenId));
  }

  const debouncedState = useDebouncedCallback(async (value: string) => {
    try {
      const externalContactResult = await prodocApi.externalContact.search(value);
      if (externalContactResult.length === 0) {
        externalContactResult.push({ name: `${value} (Ny)`, id: '0' });
      }
      setExternalContacts(externalContactResult);
      setisLoading(true);
    } finally {
      setisLoading(false);
    }
  }, 300);
  const { getCityName } = useZipCode();

  const { callback: debounceSearch } = debouncedState;

  async function handleNameChange(value: string, label: string) {
    if (value === '0') {
      const l = label.replace(' (Ny)', '');
      setExternalContact({ ...externalContact, name: l });
      validate({ ...externalContact, name: l });
      setDisabled(false);
    } else {
      var c = await prodocApi.externalContact.get(value);
      const dto = { ...c, citizenId };
      setExternalContact(dto);
      validate(dto);
      setDisabled(true);
    }
  }

  const { name, email, phone, subCategoryId, categoryId, address, zipCode } = externalContact;

  return (
    <div className="center-page page">
      <form className="center-form item-form">
        <Card>
          <Row gutter={8}>
            <Col span={12}>
              <Auto
                disabled={isEdit}
                options={externalContacts}
                onSearch={debounceSearch}
                valueProp="id"
                labelProp="name"
                onChange={handleNameChange}
                value={externalContact.name}
                error={errors.name}
              />
            </Col>
            <Col span={6}>
              <SelectMenu
                title="Kategori"
                value={categoryId}
                valueKey="id"
                labelKey="title"
                name="categoryId"
                options={externalContactCategories}
                onChange={handleFieldsChange}
                disabled={disabled || isEdit}
                error={errors.categoryId}
              />
            </Col>
            <Col span={6}>
              <SelectMenu
                title="Under kategori"
                value={subCategoryId}
                valueKey="id"
                labelKey="title"
                name="subCategoryId"
                options={externalContactCategories.find(x => x.id === externalContact?.categoryId)?.subCategories}
                onChange={handleFieldsChange}
                disabled={!categoryId || disabled || isEdit}
                error={errors.subCategoryId}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <TextInput
                title="Adresse"
                name="address"
                value={address}
                onChange={handleFieldsChange}
                disabled={disabled}
              />
            </Col>
            <Col span={6}>
              <TextInput
                title="Postnummer"
                name="zipCode"
                value={zipCode}
                onChange={handleFieldsChange}
                disabled={disabled}
              />
            </Col>
            <Col span={6}>
              <TextInput title="By" name="subCategori" readonly value={getCityName(zipCode)} disabled={disabled} />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <TextInput title="Email" name="email" value={email} onChange={handleFieldsChange} disabled={disabled} />
            </Col>
          </Row>
          <CardActions
            rightActions={
              <>
                <Button title={isEdit ? 'Gem' : 'Tilføj'} type="primary" onClick={handleOk} />
                <Button title="Annuller" onClick={handleCancel} />
              </>
            }
          />
        </Card>
      </form>
    </div>
  );
}
